<template>
  <UcSVG
    v-bind="{ type: 'solid', ...eventStatusProps.icon }"
    :height="height"
    :width="width"
    origin="most-webapp"
    :class="[$style.statusIcon, $style[eventStatusProps.color]]"
  />
</template>

<script>
import { UcSVG } from '@unyc/common-components';

import { findEventStatus } from '../lib/event.js';

export default {
  name: 'EventStatusIcon',
  components: { UcSVG },
  props: {
    event: {
      type: Object,
      default: null,
    },
    width: {
      type: String,
      default: '20px',
    },
    height: {
      type: String,
      default: '20px',
    },
  },
  computed: {
    eventStatusProps() {
      return findEventStatus(this.event);
    },
  },
};
</script>

<style lang="scss" module>
.statusIcon {
  &.primary {
    color: var(--color-primary-500-hex);
  }

  &.secondary {
    color: var(--color-secondary-500-hex);
  }

  &.success {
    color: var(--color-success-500-hex);
  }

  &.warning {
    color: var(--color-warning-500-hex);
  }

  &.danger {
    color: var(--color-danger-500-hex);
  }
}
</style>
