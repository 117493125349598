import { createToastInterface, POSITION, TYPE } from 'vue-toastification';

import BaseToast from './components/base-toast.vue';

const pluginOptions = {
  transition: 'Vue-Toastification__fade',
  position: POSITION.BOTTOM_CENTER,
  timeout: 10000,
  draggabe: true,
  maxToasts: 8,
  icon: false,
  closeButton: false,
  hideProgressBar: true,
};

const toastPluginInterface = createToastInterface(pluginOptions);

const baseToastTemplates = {
  [TYPE.DEFAULT]: {
    color: 'primary',
  },
  [TYPE.INFO]: {
    color: 'help',
    icon: {
      type: 'solid',
      name: 'info-circle',
    },
  },
  [TYPE.SUCCESS]: {
    color: 'success',
    icon: {
      type: 'solid',
      name: 'check-circle',
    },
  },
  [TYPE.ERROR]: {
    color: 'danger',
    icon: {
      type: 'solid',
      name: 'cross-circle',
    },
  },
  [TYPE.WARNING]: {
    color: 'warning',
    icon: {
      type: 'solid',
      name: 'exclamation-circle',
    },
  },
};

export function toast(body, toastOptions = {}) {
  const templateName = toastOptions.type || TYPE.DEFAULT;
  toastPluginInterface(
    {
      component: BaseToast,
      props: {
        body,
        title: toastOptions.title,
        icon: toastOptions.icon,
        ...baseToastTemplates[templateName],
      },
    },
    {
      ...toastOptions,
    },
  );
}

const installPlugin = (Vue) => {
  Vue.$toast = toast;
  Vue.prototype.$toast = toast;
};

export default installPlugin;
