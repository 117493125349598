export const mixinDetectingMobile = {
  computed: {
    isMobile() {
      return this.$mq === 'md' || this.$mq === 'sm';
    },
  },
};

export const mixinIsPage = {
  data() {
    return {
      isPageLoading: true,
    };
  },
  created() {
    this.isPageLoading = true;
    this.$emit('is-loading');
  },
  async mounted() {
    if (this.mountedBeforeCallback) {
      await this.mountedBeforeCallback();
    }
    this.isPageLoading = false;
    this.$emit('is-loaded');
  },
};

export const mixinModal = {
  data() {
    return {
      isModalVisible: false,
      showModal: false,
    };
  },
  methods: {
    openModal() {
      this.isModalVisible = true;
      this.showModal = true;
    },
    closeModal() {
      this.isModalVisible = false;
      this.showModal = false;
      this.editAlert = false;
    },
  },
};
