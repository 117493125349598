<template>
  <div>
    <div
      v-for="subCategories in subscriptions"
      :key="subCategories.uuid"
      :class="$style.notificationsServiceBlock"
    >
      <div :class="$style.notificationServiceName">
        {{ subCategories.name }}
      </div>
      <div :class="$style.numberOfSubscriber">
        <div :class="$style.numberOfSubscriberTitle">
          {{ $t('subscriptionCenterBody.numberOfSubscriberTtile') }}
        </div>
        <div :class="$style.numberOfSubscriberBody">
          {{
            $tc(
              'subscriptionCenterBody.numberOfSubscriberBody',
              subCategories.emails.length,
              { count: subCategories.emails.length },
            )
          }}
        </div>
      </div>
      <div :class="$style.numberOfSubscriber">
        <div :class="$style.numberOfSubscriberTitle">
          {{ $t('subscriptionCenterBody.numberOfServiceTitle') }}
        </div>
        <div :class="$style.numberOfSubscriberBody">
          {{
            $tc(
              'subscriptionCenterBody.numberOfServiceBody',
              subCategories.categories.length,
              {
                count: subCategories.categories.length,
              },
            )
          }}
        </div>
      </div>
      <div :class="$style.blockManage">
        <UcChoice
          type="switch"
          color="success"
          :value="subCategories.enable"
          @input="updateSubscriptionService(subCategories, $event)"
        ></UcChoice>
        <UcDropdownMenu>
          <UcSVG
            slot="button"
            origin="most-webapp"
            name="menu-dots"
            type="regular"
            height="13px"
            width="16px"
            :class="$style.managementIcon"
            @click="openModal"
          ></UcSVG>
          <UcDropdownMenuItem
            id="edit"
            :icon="{ name: 'pencil', type: 'regular' }"
            @click="editModalSubscription(subCategories)"
            >{{
              $t('subscriptionCenterBody.dropDownMenuEdit')
            }}</UcDropdownMenuItem
          >
          <UcDropdownMenuItem
            id="delete"
            :icon="{ name: 'trash', type: 'regular' }"
            color="danger"
            @click="deleteSubscription(subCategories.uuid)"
            >{{
              $t('subscriptionCenterBody.dropDownMenuDelete')
            }}</UcDropdownMenuItem
          >
        </UcDropdownMenu>
      </div>
    </div>
  </div>
</template>

<script>
import {
  UcChoice,
  UcSVG,
  UcDropdownMenu,
  UcDropdownMenuItem,
} from '@unyc/common-components';
import { mixinModal } from '../mixins';

export default {
  components: {
    UcChoice,
    UcSVG,
    UcDropdownMenu,
    UcDropdownMenuItem,
  },
  mixins: [mixinModal],
  props: {
    subscriptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      userEmail: this.$keycloak.idTokenParsed.email,
      subscrptionEventCount: 0,
      switchButton: true,
    };
  },
  methods: {
    updateSubscriptionService(subCategories, value) {
      subCategories.enable = value;
      this.$emit('update-subscription', subCategories);
    },
    deleteSubscription(subscriptionId) {
      this.$emit('delete-subscription', subscriptionId);
    },
    editModalSubscription(subscription) {
      this.$emit('open-edit-modal', subscription);
    },
  },
};
</script>

<style lang="scss" module>
@import '../assets/scss/variables';

.notificationsServiceBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--base-spacing-lg) var(--base-spacing-xs);
  margin-top: var(--base-spacing-6xl);
  border-radius: 4px;
  box-shadow: var(--base-shadow-sm);

  .notificationServiceName {
    width: 150px;
    font-size: var(--base-spacing-md);
    font-weight: 500;
  }

  .numberOfSubscriber {
    display: grid;

    .numberOfSubscriberTitle {
      font-size: var(--base-spacing-sm);
      font-weight: 500;
    }

    .numberOfSubscriberBody {
      font-size: var(--base-spacing-sm);
      font-weight: 300;
    }
  }

  .blockManage {
    display: flex;
    align-items: center;

    .managementIcon {
      margin-left: var(--base-spacing-xl);
      cursor: pointer;
    }
  }
}
</style>
