<template>
  <BaseCard>
    <template #header>
      <router-link :class="$style.header" tag="div" :to="eventUrl">
        <EventStatusIcon
          :event="event"
          height="20px"
          width="20px"
          :class="$style.icon"
        />
        <div :class="$style.headingTextWrapper">
          {{ event.description }}
          <span :class="$style.subHeading">- {{ businessServicesName }}</span>
        </div>
      </router-link>

      <slot name="header-right" :event="event" />
    </template>

    <div :class="$style.comments">
      <EventHistoryComment
        v-for="comment in reversedComments"
        :key="comment.uuid"
        :content="comment.content"
        :status="$t(`eventStatus.${comment._eventStatus}`)"
        :created-at="comment.createdAt"
      />
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from './base-card.vue';

import EventStatusIcon from './event-status-icon.vue';
import EventHistoryComment from './event-history-comment.vue';

export default {
  name: 'EventHistoryListItem',
  components: {
    BaseCard,
    EventStatusIcon,
    EventHistoryComment,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      reversedComments: null,
    };
  },
  computed: {
    businessServicesName() {
      const businessServices = this.event._technicalService._businessServices;
      if (businessServices.length == 1) {
        return businessServices[0].name;
      }
      return `${businessServices.length} ${this.$t('event.impactedServices')}`;
    },
    eventUrl() {
      return {
        name: 'Event',
        params: { uuid: this.event.uuid },
      };
    },
  },
  mounted() {
    this.reversedComments = [...this.event._comments].reverse();
  },
};
</script>

<style lang="scss" module>
.header {
  display: flex;
  align-items: center;
  cursor: pointer;

  .headingTextWrapper {
    margin-left: var(--base-spacing-sm);
  }

  .heading {
    font-weight: 500;
    color: var(--base-font-color);
  }

  .subHeading {
    margin-left: var(--base-spacing-xxs);
    font-weight: 300;
  }

  .icon {
    flex-shrink: 0;
  }
}

.comments {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>
