<template>
  <UcSVG
    :name="categoryStatusProps.icon.name"
    :height="height"
    :width="width"
    type="solid"
    origin="most-webapp"
    :class="$style[categoryStatusProps.color]"
  />
</template>

<script>
import { UcSVG } from '@unyc/common-components';

import { findCategoryStatus } from '../lib/category.js';

export default {
  name: 'CategoryStatusIcon',
  components: { UcSVG },
  props: {
    status: {
      type: String,
      required: true,
      validator: (obj) => {
        const status = ['up', 'down', 'partial', 'critical', 'maintenance'];
        return status.includes(obj);
      },
    },
    width: {
      type: String,
      default: '20px',
    },
    height: {
      type: String,
      default: '20px',
    },
  },
  computed: {
    categoryStatusProps() {
      const { color, icon } = findCategoryStatus(this.status);
      return { color, icon };
    },
  },
};
</script>

<style lang="scss" module>
.primary {
  color: var(--color-primary-500-hex);
  opacity: 0.25;
}

.secondary {
  color: var(--color-secondary-500-hex);
  opacity: 0.25;
}

.success {
  color: var(--color-success-500-hex);
}

.warning {
  color: var(--color-warning-500-hex);
}

.danger {
  color: var(--color-danger-500-hex);
}
</style>
