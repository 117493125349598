<template>
  <div :class="$style.currentEventsList">
    <h2>
      {{ $t('eventHistoryBlock.inProgressIncidents') }} - {{ events.length }}
    </h2>
    <ol>
      <li v-for="eventItem in events" :key="eventItem.uuid">
        <EventHistoryListItem :class="$style.eventItem" :event="eventItem">
          <template v-if="$keycloak.authenticated" #header-right="{ event }">
            <div>
              <UcButton
                outlined
                :class="$style.subscribeButton"
                @click="openModal(event.uuid)"
              >
                Suivre l'incident
                <template #after>
                  <UcSVG
                    :class="$style.bell"
                    origin="most-webapp"
                    name="bell"
                    type="solid"
                    height="15px"
                    width="15px"
                  />
                </template>
              </UcButton>
              <UcModal
                :visible.sync="isEventModalVisible[event.uuid]"
                @close="closeEventModal"
              >
                <div :class="$style.modalIncident">
                  <UcSVG
                    origin="most-webapp"
                    name="bell"
                    type="solid"
                    height="30px"
                    width="30px"
                  ></UcSVG>
                  <div :class="$style.modalBodyText">
                    <p :class="$style.modalIncidentTitle">Suivre l’incident</p>
                    <p :class="$style.modalIncidentBody">
                      Me notifier lors d'une mise à jour sur l'incident :
                    </p>
                    <p :class="$style.modalIncidentBodyDetails">
                      “{{ event.description }}”
                    </p>
                  </div>
                  <div :class="$style.formButtons">
                    <UcButton outlined @click="closeEventModal">
                      Annuler
                    </UcButton>
                    <UcButton outlined @click="subscribeToEvent(event)">
                      Suivre l'incident
                    </UcButton>
                  </div>
                </div>
              </UcModal>
            </div>
          </template>
        </EventHistoryListItem>
      </li>
    </ol>
  </div>
</template>

<script>
import EventHistoryListItem from './event-history-list-item.vue';
import { UcButton, UcModal, UcSVG } from '@unyc/common-components';
import { statusApiSubscritpionEvent } from '../services/models/subscriptionevent';

export default {
  name: 'CurrentEventsList',
  components: {
    EventHistoryListItem,
    UcButton,
    UcModal,
    UcSVG,
  },
  props: {
    events: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isEventModalVisible: {},
    };
  },
  mounted() {
    this.resetModals();
  },
  methods: {
    subscribeToEvent(event) {
      const data = {
        emails: [this.$keycloak.idTokenParsed.email],
        event: event.uuid,
      };

      statusApiSubscritpionEvent
        .post(data)
        .then(() => {
          this.$toast(
            this.$i18n.t('notificationsSubscriptionModal.alertAdded'),
            {
              type: 'success',
            },
          );
        })
        .catch(() => {
          this.$toast(this.$i18n.t('errorToast.add'), {
            type: 'error',
          });
        });
      this.closeEventModal();
    },
    openModal(eventId) {
      this.resetModals();
      this.isEventModalVisible[eventId] = true;
    },
    closeEventModal() {
      this.resetModals();
    },
    resetModals() {
      this.isEventModalVisible = this.events.map((event) => {
        return { [event.uuid]: false };
      });
    },
  },
};
</script>
<style lang="scss" module>
.currentEventsList {
  h2 {
    margin-bottom: var(--base-spacing-xxl);
    font-size: 22px;
    font-weight: 500;
  }

  .eventItem {
    margin-bottom: var(--base-spacing-xxl);
  }
}

.subscribeButton {
  min-width: 170px;

  &:hover {
    .bell {
      fill: white;
    }
  }
}

.formButtons :last-child {
  margin-left: 15px;
}

.modalIncident {
  display: flex;
  flex-direction: column;
  align-items: center;

  .modalIncidentTitle {
    font-size: 23px;
    font-weight: 400;
    line-height: 32px;
  }

  .modalBodyText {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 25px 0 25px 0;
  }

  .modalIncidentBody {
    font-size: 16px;
    font-weight: 200;
    line-height: 24px;
  }

  .modalIncidentBodyDetails {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}
</style>
