<template>
  <div :class="$style.descriptionItem">
    <p :class="$style.label">{{ label }}</p>
    <p :class="$style.value">
      <slot name="default">{{ value }}</slot>
    </p>
  </div>
</template>

<script>
export default {
  name: 'BaseDescriptionItem',
  props: {
    label: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" module>
.descriptionItem {
  display: flex;
  flex: 50%;
  flex-direction: column;
  gap: var(--base-spacing-xs);

  .label {
    font-size: 14px;
  }

  .value {
    font-size: 12px;
    font-weight: 300;
    color: var(--grey-500-hex);
  }
}
</style>
