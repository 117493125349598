/**
 * Category functions and definitions
 */

export const getMostCriticalStatusFromCategories = (categories) => {
  const down = categories.filter((category) => category.status == 'down');
  if (down.length > 0) {
    return 'down';
  }
  const critical = categories.filter(
    (category) => category.status == 'critical',
  );
  if (critical.length > 0) {
    return 'critical';
  }
  const partial = categories.filter((category) => category.status == 'partial');
  if (partial.length > 0) {
    return 'partial';
  }
  return 'up';
};

export const CATEGORY_STATUS_DICT = Object.freeze({
  up: { color: 'success', icon: { name: 'check-circle' } },
  partial: { color: 'warning', icon: { name: 'exclamation-circle' } },
  critical: { color: 'danger', icon: { name: 'exclamation-circle' } },
  down: { color: 'danger', icon: { name: 'cross-circle' } },
  maintenance: { color: 'secondary', icon: { name: 'cross-circle' } },
  unknown: { color: 'secondary', icon: { name: 'info-circle' } },
});

export const findCategoryStatus = (status) =>
  CATEGORY_STATUS_DICT[status] || CATEGORY_STATUS_DICT.unknown;
