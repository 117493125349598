import * as ACTIONS_TYPES from './actions-types';
import * as MUTATIONS_TYPES from './mutations-types';

import { statusApiCategoryService } from '../../../services/models/category.js';

export const actions = {
  [ACTIONS_TYPES.FETCH_MAIN_CATEGORIES]({ commit }) {
    return new Promise((resolve, reject) => {
      statusApiCategoryService
        .list({ ['is_main']: true })
        .then((response) => {
          commit(MUTATIONS_TYPES.SET_MAIN_CATEGORIES, response.data.results);
          resolve(response.data);
        })
        .catch((error) => {
          commit(MUTATIONS_TYPES.SET_MAIN_CATEGORIES, []);
          reject(error);
        });
    });
  },
};
