import Vue from 'vue';
import Vuex from 'vuex';

import status from './modules/status';

Vue.use(Vuex);

const modules = {
  status,
};

export default new Vuex.Store({
  modules,
});
