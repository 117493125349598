<template>
  <div>
    <div :class="$style.searchStyle">
      <UcInput
        v-model="searchQuery"
        :icon-left="{ name: 'search' }"
        placeholder="Rechercher..."
        :class="$style.inputSearchField"
        @input="handleInput()"
      >
      </UcInput>
    </div>
    <div :class="$style.switchArea">
      <div
        v-for="parent in categories"
        :key="parent.uuid"
        :class="$style.blockService"
      >
        <UcChoice
          :class="$style.switchCategories"
          :value="value.includes(parent.uuid)"
          type="switch"
          color="success"
          @change="toogleSelectCategories(parent)"
        >
          <template #before>
            <div :class="$style.parentService">
              <div>{{ parent.name }}</div>
            </div>
          </template>
        </UcChoice>
        <div
          v-for="child in parent.child"
          :key="child.uuid"
          :class="$style.childService"
        >
          <div>{{ child.name }}</div>
          <div :class="$style.blockStatusSwitch">
            <div :class="$style.childStatus">
              <CategoryStatusIcon
                :class="$style.iconChildStatus"
                :status="child.status"
                width="12px"
                height="12px"
              ></CategoryStatusIcon>
              {{ child.status }}
            </div>
            <UcChoice
              :class="$style.switchChild"
              :value="value.includes(child.uuid) || value.includes(parent.uuid)"
              :disabled="value.includes(parent.uuid)"
              type="switch"
              color="success"
              @change="toogleSelectCategories(child)"
            >
            </UcChoice>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { UcInput, UcChoice } from '@unyc/common-components';
import { statusApiCategoryService } from '../services/models/category';
import CategoryStatusIcon from './category-status-icon.vue';
export default {
  components: {
    UcInput,
    UcChoice,
    CategoryStatusIcon,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      searchQuery: '',
      categories: [],
    };
  },
  watch: {
    value(currentValue) {
      this.$emit('input', currentValue);
    },
  },
  mounted() {
    this.handleInput();
  },
  methods: {
    toogleSelectCategories(category) {
      let result = this.value;
      let childCategoriesIds = [];
      if (category.child) {
        childCategoriesIds = category.child.map((child) => child.uuid);
      }

      if (this.value.includes(category.uuid)) {
        result = this.value
          .filter((c) => c != category.uuid)
          .filter((c) => !childCategoriesIds.includes(c));

        this.$emit('input', result);
        return;
      }
      result.push(category.uuid);

      childCategoriesIds.map((c) =>
        result.includes(c) ? false : result.push(c),
      );
      this.$emit('input', result);
    },
    fetchCategories(search) {
      return statusApiCategoryService.list({ search }).then((response) => {
        this.categories = this.processCategories(response.data.results);
      });
    },
    processCategories(jsonResults) {
      const parentCategories = jsonResults
        .map((item) => {
          if (item._parentCategory === null) {
            return item;
          } else {
            return item._parentCategory;
          }
        })
        .filter((v, i, a) => a.findIndex((v2) => v2.uuid === v.uuid) === i); // remove duplicated values
      parentCategories.forEach((parentCategorie) => {
        parentCategorie.child = jsonResults.filter(
          (item) => item.parentCategory === parentCategorie.uuid,
        );
      });
      return parentCategories;
    },
    handleInput() {
      this.fetchCategories(this.searchQuery);
    },
  },
};
</script>
<style lang="scss" module>
@import '../assets/scss/variables';

.searchStyle {
  padding: var(--base-spacing-sm);
  background-color: var(--grey-25-hex);
  border-radius: var(--base-spacing-xxs);
  border-bottom-right-radius: unset;
  border-bottom-left-radius: unset;

  .inputSearchField {
    width: 100% !important;
    background-color: var(--base-background-color) !important;
  }
}

.switchArea {
  border: solid 1px var(--grey-25-hex);
  border-radius: var(--base-spacing-xxs);
  border-top-left-radius: unset;
  border-top-right-radius: unset;

  .switchCategories {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: var(--base-spacing-xl);
  }

  .switchChild {
    display: contents;
  }

  .blockService {
    padding: var(--base-spacing-xs) 0;
    margin: var(--base-spacing-sm);
    background-color: var(--grey-25-hex);
    border-radius: var(--base-spacing-xxs);
  }

  .parentService {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px 16px;
    border-radius: var(--base-spacing-xxs);
  }

  .childService {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: var(--base-spacing-sm);
    margin: var(--base-spacing-sm);
    background-color: var(--base-background-color);
    border: 1px solid #ebebeb;
    border-radius: var(--base-spacing-xxs);

    .blockStatusSwitch {
      display: flex;
      align-items: center;
    }

    .childStatus {
      display: flex;
      align-items: center;
      margin: 0 var(--base-spacing-xl);
      font-family: 'Orkney', sans-serif;
      font-size: var(--base-spacing-sm);
      font-style: normal;
      font-weight: 300;
      line-height: var(--base-spacing-sm);
      color: var(--grey-300-hex);

      .iconChildStatus {
        margin: 0 var(--base-spacing-xs);
      }
    }
  }
}
</style>
