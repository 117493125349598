<template>
  <div
    :class="[$style.baseCircledIcon, { [$style.hasCircle]: circle }]"
    :style="wrapperCssProps"
  >
    <div :class="$style.icon" :style="iconCssProps" />
  </div>
</template>

<script>
export default {
  name: 'BaseCircledIcon',
  props: {
    iconSrc: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: null,
      validator: (value) => {
        return [
          'primary',
          'secondary',
          'success',
          'warning',
          'danger',
          'help',
          null,
        ].includes(value);
      },
    },
    circle: {
      type: Boolean,
      default: true,
    },
    width: {
      type: String,
      default: '20px',
    },
    height: {
      type: String,
      default: '20px',
    },
  },
  computed: {
    wrapperCssProps() {
      const { color, width, height } = this;
      const cssVariable = color
        ? `var(--color-${color}-500-hex)`
        : 'transparent';
      return {
        '--circled-icon-color': cssVariable,
        '--circled-icon-wrapper-width': width,
        '--circled-icon-wrapper-height': height,
      };
    },
    iconCssProps() {
      const { iconSrc } = this;

      const transparentPixel =
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
      const maskImage = iconSrc ? iconSrc : transparentPixel;

      return {
        'mask-image': `url('${maskImage}')`,
      };
    },
  },
};
</script>

<style lang="scss" module>
@import '../assets/scss/variables';

.baseCircledIcon {
  align-items: center;
  width: var(--circled-icon-wrapper-width);
  height: var(--circled-icon-wrapper-height);
  border-color: var(--circled-icon-color) !important;

  &.hasCircle {
    display: flex;
    justify-content: center;
    border: solid 1px var(--circled-icon-color);
    border-radius: 26px;

    .icon {
      width: 50%;
      height: 50%;
    }
  }

  .icon {
    width: var(--circled-icon-wrapper-width);
    height: var(--circled-icon-wrapper-height);
    background-color: var(--circled-icon-color) !important;
    mask-repeat: no-repeat;
    mask-origin: content-box;
    mask-size: contain;
  }
}
</style>
