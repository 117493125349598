import { statusApi } from '../index';

const resource = '/subscriptions/categories/';

export const statusApiSubscritpionCategory = {
  post(body) {
    return statusApi.post(resource, body);
  },
  get(uuid) {
    return statusApi.get(`${resource}${uuid}/`);
  },
  getAll() {
    return statusApi.get(resource);
  },
  update(uuid, body) {
    return statusApi.patch(`${resource}${uuid}/`, body);
  },
  delete(uuid) {
    return statusApi.delete(`${resource}${uuid}/`);
  },
};
