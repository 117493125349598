<template>
  <div>
    <section :class="$style.headerSubscriptionsSection">
      <div :class="$style.headerTitle">
        {{ $t('sharedInterface.mySubscription') }}
      </div>
      <div>
        <UcButton @click="handleClickAddSubscription">
          {{ $t('subscription.addAlert') }}
          <template #before>
            <UcSVG name="plus-circle" />
          </template>
        </UcButton>
        <UcModal
          :visible.sync="isModalVisible"
          :fullscreen="true"
          :custom-body-class="$style.modalBodyClass"
          @close="closeModal"
        >
          <template #header> {{ $t('subscription.addAlert') }} </template>
          <SubscriptionsForm
            :form-values="formValues"
            :edit-mode="formEditMode"
            :class="$style.bodyModal"
            @submit-data="submitData"
            @update-enable-value="updateValueCategory"
          ></SubscriptionsForm>
        </UcModal>
      </div>
    </section>
    <section>
      <UcTabs>
        <UcTab
          id="tab-services"
          :figure="subscriptionsService.length.toString()"
          :title="$t('subscriptionCenterBar.service')"
        >
          <SubscriptionCenterServicesList
            v-if="subscriptionsService.length > 0"
            :subscriptions="subscriptionsService"
            @update-subscription="updateValueCategory"
            @delete-subscription="deleteCategorySubscription"
            @open-edit-modal="handleClickEditSubscription"
            @onToggleEnableSubscription="updateServiceSubscription(event)"
          />
          <div v-else :class="$style.noAlertContainer">
            <div :class="$style.noAlertBlock">
              <p :class="$style.noAlertTitle">
                {{ $t('subscriptionCenterBody.noSubscriptionTitle') }}
              </p>
              <p :class="$style.noAlertBody">
                {{ $t('subscriptionCenterBody.noSubscriptionBody') }}
              </p>
            </div>
          </div>
        </UcTab>
        <UcTab
          id="tab-alerts"
          :title="$t('subscriptionCenterBar.event')"
          :figure="subscriptionsAlerts.length"
        >
          <SubscriptionCenterAlertList
            v-if="subscriptionsAlerts.length > 0"
            :subscriptions="subscriptionsAlerts"
            @update-enable-value="updateValueEvent"
            @delete-subscription="deleteEventSubscription"
            @onToggleEnableSubscription="updateServiceSubscription(event)"
          />
          <div v-else :class="$style.noAlertContainer">
            <div :class="$style.noAlertBlock">
              <p :class="$style.noAlertTitle">
                {{ $t('subscriptionCenterBody.noSubscriptionTitle') }}
              </p>
              <p :class="$style.noAlertBody">
                {{ $t('subscriptionCenterBody.noSubscriptionBody') }}
              </p>
            </div>
          </div>
        </UcTab>
      </UcTabs>
    </section>
  </div>
</template>
<script>
import {
  UcModal,
  UcSVG,
  UcButton,
  UcTabs,
  UcTab,
} from '@unyc/common-components';
import SubscriptionsForm from '../components/subscriptions-form';
import SubscriptionCenterServicesList from './subscription-center-services-list';
import SubscriptionCenterAlertList from './subscription-center-alert-list.vue';
import { statusApiSubscritpionCategory } from '../services/models/subscriptioncategory';
import { statusApiSubscritpionEvent } from '../services/models/subscriptionevent';
import { mixinModal } from '../mixins';

export default {
  components: {
    UcModal,
    UcSVG,
    UcTabs,
    UcTab,
    UcButton,
    SubscriptionsForm,
    SubscriptionCenterAlertList,
    SubscriptionCenterServicesList,
  },
  mixins: [mixinModal],
  data() {
    return {
      subCategories: [],
      userEmail: this.$keycloak.idTokenParsed.email,
      subscriptionsService: [],
      subscriptionsAlerts: [],
      formValues: {
        subscriptionName: '',
        selectedCategories: [],
        emailList: [],
        formEdit: false,
        uuid: '',
      },
      formEditMode: false,
    };
  },
  mounted() {
    this.fetchSubscriptionsCategories();
    this.fetchSubscriptionsEvents();
  },
  methods: {
    handleClickAddSubscription() {
      this.formValues.subscriptionName = '';
      this.formValues.selectedCategories = [];
      this.formValues.emailList = [this.$keycloak.idTokenParsed.email];
      this.formEditMode = false;
      this.openModal();
    },
    handleClickEditSubscription(subscription) {
      this.formValues.subscriptionName = subscription.name;
      this.formValues.selectedCategories = subscription.categories;
      this.formValues.uuid = subscription.uuid;
      this.formValues.emailList = subscription.emails;
      this.formEditMode = true;
      this.openModal();
    },
    submitData(formValues) {
      const data = {
        name: formValues.subscriptionName,
        emails: formValues.emailList,
        categories: formValues.selectedCategories,
      };
      statusApiSubscritpionCategory
        .post(data)
        .then((response) => {
          console.log(response.data);
          this.fetchSubscriptionsCategories();
          this.checkAlertAdded();
          this.closeModal();
        })
        .catch((error) => {
          console.error(error, 'error');
        });
    },
    async updateValueEvent(subEvent) {
      const data = {
        emails: subEvent.emailList,
        enable: subEvent.enable,
      };

      statusApiSubscritpionEvent
        .update(subEvent.uuid, data)
        .then(() => {
          this.fetchSubscriptionsEvents();
          this.checkAlertEdited();
        })
        .catch((error) => {
          this.checkError(error, 'updateValue');
        });
    },
    async updateValueCategory(subCategory) {
      const data = {
        name: subCategory.subscriptionName,
        categories: subCategory.selectedCategories,
        emails: subCategory.emailList,
        enable: subCategory.enable,
      };

      statusApiSubscritpionCategory
        .update(subCategory.uuid, data)
        .then(() => {
          this.closeModal();
          this.fetchSubscriptionsCategories();
          this.checkAlertEdited();
        })
        .catch((error) => {
          this.checkError(error, 'updateValue');
        });
    },
    async fetchSubscriptionsCategories() {
      return statusApiSubscritpionCategory
        .getAll()
        .then((response) => {
          this.subscriptionsService = response.data.results;
        })
        .catch((error) => {
          this.checkError(error, 'fetchSubscriptionsCategory');
        });
    },
    async fetchSubscriptionsEvents() {
      return statusApiSubscritpionEvent
        .getAll()
        .then((response) => {
          this.subscriptionsAlerts = response.data.results;
        })
        .catch((error) => {
          this.checkError(error, 'fetchSubscriptionsEvent');
        });
    },
    async deleteCategorySubscription(subscriptionId) {
      statusApiSubscritpionCategory
        .delete(subscriptionId)
        .then(() => {
          this.subscriptionsService = this.subscriptionsService.filter(
            (subscription) => subscription.uuid !== subscriptionId,
          );
          this.checkAlertDeleted();
        })
        .catch((error) => {
          this.checkError(error, 'delete');
        });
    },
    async deleteEventSubscription(subscriptionId) {
      statusApiSubscritpionEvent
        .delete(subscriptionId)
        .then(() => {
          this.subscriptionsAlerts = this.subscriptionsAlerts.filter(
            (subscription) => subscription.uuid !== subscriptionId,
          );
          this.checkAlertDeleted();
        })
        .catch((error) => {
          this.checkError(error, 'delete');
        });
    },
    checkAlertEdited() {
      this.$toast(this.$i18n.t('notificationsSubscriptionModal.alertEdited'), {
        type: 'success',
      });
    },
    checkAlertDeleted() {
      this.$toast(this.$i18n.t('notificationsSubscriptionModal.alertDeleted'), {
        type: 'success',
      });
    },
    checkAlertAdded() {
      this.$toast(this.$i18n.t('notificationsSubscriptionModal.alertAdded'), {
        type: 'success',
      });
    },
    checkError(error, displayToast) {
      const errorMessages = {
        delete: this.$i18n.t('errorToast.delete', error),
        fetchSubscriptionsEvent: this.$i18n.t(
          'errorToast.fetchSubscriptionsEvent',
          error,
        ),
        fetchSubscriptionsCategory: this.$i18n.t(
          'errorToast.fetchSubscriptionsCategory',
          error,
        ),
        updateValue: this.$i18n.t('errorToast.updateValue', error),
      };

      const errorMessage =
        errorMessages[displayToast] || this.$i18n.t('error', error);
      this.$toast(errorMessage, {
        type: 'error',
      });
    },
  },
};
</script>

<style lang="scss" module>
@import '../assets/scss/variables';

.headerSubscriptionsSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 40px;
}

.headerTitle {
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  line-height: 40px;
}

.bodyModal {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.noAlertContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.noAlertBlock {
  display: grid;
  align-items: center;
  width: 400px;
  margin-top: var(--base-spacing-xxl);
  text-align: center;

  .noAlertTitle {
    margin-bottom: var(--base-spacing-xl);
    font-size: var(--base-spacing-xl);
    font-weight: 500;
  }

  .noAlertBody {
    font-size: var(--base-spacing-sm);
    font-weight: 300;
  }
}

@media screen and (max-width: $xl) {
  .headerSubscriptionsSection {
    flex-direction: column;
    margin-bottom: 80px;
  }

  .bodyModal {
    flex-direction: column;
    width: 100%;
  }

  .modalBodyClass {
    flex-direction: column;
    align-items: center !important;
  }
}
</style>
