<template>
  <div :class="$style.eventPage">
    <BaseBackLink />

    <EventDetailsCard v-if="event" :event="event" />

    <BaseCard v-if="eventHasComments" :class="$style.eventHistory">
      <p :class="$style.heading">{{ $t('event.incidentDetails') }}</p>
      <div :class="$style.list">
        <EventHistoryComment
          v-for="(comment, index) in eventComments"
          :key="comment.uuid"
          :content="comment.content"
          :status="$t(`eventStatus.${comment._eventStatus}`)"
          :created-at="comment.createdAt"
        >
          <BaseSeparator
            v-if="index < eventComments.length - 1"
            :class="$style.xlSeparator"
          />
        </EventHistoryComment>
      </div>
    </BaseCard>
  </div>
</template>

<script>
import { statusApiEventService } from '../services/models/event.js';
import { mixinIsPage } from '@/mixins.js';

import BaseCard from '../components/base-card.vue';
import BaseBackLink from '../components/base-back-link';
import BaseSeparator from '../components/base-separator.vue';

import EventDetailsCard from '../components/event-details-card.vue';
import EventHistoryComment from '../components/event-history-comment.vue';

export default {
  name: 'EventPage',
  components: {
    BaseCard,
    BaseSeparator,
    BaseBackLink,
    EventDetailsCard,
    EventHistoryComment,
  },
  mixins: [mixinIsPage],
  data() {
    return {
      event: null,
    };
  },
  computed: {
    eventHasComments() {
      return this.event?._comments?.length > 0;
    },
    eventComments() {
      const { eventHasComments, event } = this;
      return eventHasComments ? event._comments : [];
    },
  },
  methods: {
    sortCommentsByDate() {
      this.eventComments.sort((a, b) => {
        return new Date(a.createdAt) - new Date(b.createdAt);
      });
    },
    fetchEventDetails() {
      const { uuid: eventUuid } = this.$route.params;
      return statusApiEventService.get(eventUuid).then((response) => {
        this.event = response.data;
      });
    },
    async mountedBeforeCallback() {
      await this.fetchEventDetails();
      this.sortCommentsByDate();
    },
  },
};
</script>

<style lang="scss" module>
.eventPage {
  .eventHistory {
    margin-top: var(--base-spacing-5xl);

    .heading {
      margin-bottom: var(--base-spacing-xs);
      font-size: 18px;
      font-weight: 300;
      line-height: 2.22;
    }

    .list {
      display: flex;
      flex-direction: column;

      .xlSeparator {
        margin: var(--base-spacing-xl) 0;
      }
    }
  }
}
</style>
