<template>
  <UcSVG
    v-tooltip="message"
    origin="most-webapp"
    name="question-circle"
    type="regular"
    height="20px"
    :class="$style.svgTooltip"
    :style="{ cursor: 'pointer' }"
  />
</template>
<script>
import { UcSVG, UcVTooltip } from '@unyc/common-components';

export default {
  name: 'InformativTooltip',
  components: {
    UcSVG,
  },
  directives: {
    tooltip: UcVTooltip,
  },
  props: {
    message: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" module>
@import '../assets/scss/variables';

.svgTooltip {
  opacity: 0.5;
}
</style>
