<template>
  <router-link
    :to="to"
    :class="[$style.baseNavItem, $style[size]]"
    @click="toggleDisplayMobileMenu"
  >
    <span :class="$style.iconWrapper">
      <slot name="icon">
        <UcSVG v-if="icon" v-bind="icon" />
      </slot>
    </span>
    <span>
      {{ label }}
    </span>
    <slot name="status"></slot>
  </router-link>
</template>

<script>
import { UcSVG } from '@unyc/common-components';

const sizes = ['large', 'medium'];

export default {
  name: 'BaseNavItem',
  components: {
    UcSVG,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    to: {
      type: [Object, String],
      default: '#',
    },
    icon: {
      type: Object,
      default: null,
      validator: (obj) => {
        return Boolean(obj.name) || !obj;
      },
    },
    size: {
      type: String,
      required: false,
      default: 'medium',
      validator: (value) => {
        return sizes.includes(value);
      },
    },
  },
  data() {
    return {
      displayedMenu: true,
    };
  },
  methods: {
    toggleDisplayMobileMenu() {
      this.displayedMenu = !this.displayedMenu;
      this.$emit('displayed-menu', this.displayedMenu);
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" module>
@import '../assets/scss/variables';

.baseNavItem {
  display: flex;
  flex-direction: row;
  padding: var(--base-spacing-sm) var(--base-spacing-md);
  color: var(--base-background-color);
  cursor: pointer;
  border-radius: 4px;

  @media screen and (max-width: $md) {
    width: 100%;
    // padding: 2%;
  }

  &:hover {
    background-color: rgb(216 216 216 / 20%);

    a {
      color: var(--base-background-color);
    }
  }

  .iconWrapper {
    display: flex;
    align-self: center;
    margin-right: var(--base-spacing-md);
  }

  &.large {
    font-size: 1.3em;
  }
}
</style>
