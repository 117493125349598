<template>
  <div id="app" :class="$style.app">
    <div :class="[$style.view, { [$style.isLoading]: viewIsLoading }]">
      <router-view
        v-show="!(isMenuOpen && isMobile) && !viewIsLoading"
        :key="$route.path"
        :class="[$style.viewContent]"
        @is-loading="pageIsLoading"
        @is-loaded="pageIsFullyLoaded"
      >
      </router-view>
      <div :class="$style.animatedLoader"></div>
    </div>
    <TheNavbar
      :main-categories="mainCategories"
      @displayed-menu="toggleMenu"
      @display-subscription-modal="showModal"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import { ThemeCatalog } from '@unyc/common-components';
import { themeMost } from '@unyc/theme-most';

import { FETCH_MAIN_CATEGORIES } from './store/modules/status/actions-types.js';

import TheNavbar from './components/the-nav-bar.vue';
import { mixinDetectingMobile } from '@/mixins';

export default {
  name: 'App',
  components: { TheNavbar },
  mixins: [mixinDetectingMobile],
  data() {
    return {
      themes: new ThemeCatalog(),
      isMenuOpen: false,
      isFormModalSubscribeVisible: false,
      viewIsLoading: true,
    };
  },
  computed: {
    ...mapState('status', ['mainCategories']),
  },
  mounted() {
    this.applyTheme();
    this[FETCH_MAIN_CATEGORIES]();
  },
  created() {
    this.loadSVGs();
  },
  methods: {
    ...mapActions('status', [FETCH_MAIN_CATEGORIES]),
    toggleMenu(value) {
      this.isMenuOpen = value;
    },
    pageIsLoading() {
      this.viewIsLoading = true;
    },
    pageIsFullyLoaded() {
      this.viewIsLoading = false;
    },
    loadSVGs() {
      this.$setCustomSVGContexts('most-webapp', [
        require.context('@/assets/icons/', true, /\.svg$/),
        require.context('@unyc/set-common-svg/dist', true, /\.svg$/),
      ]);
    },
    applyTheme() {
      themeMost.description.variants = [];
      this.themes.install(themeMost);
      const themeName = this.themes.availableOptions[0];
      this.themes.activateTheme(themeName);
      this.themes.activateCssTheme(themeName);
      this.$setCurrentThemeSVGEnv(
        this.themes.getThemeSVGContext(themeName),
        themeName,
      );
    },
    showModal() {
      this.isFormModalSubscribeVisible = true;
    },
    closeModal() {
      this.isFormModalSubscribeVisible = false;
    },
  },
};
</script>

<style lang="scss">
@import '~@unyc/common-components/dist/common-components.css';
@import '~@unyc/theme-most/dist/assets/css/font-face.css';
</style>

<style lang="scss" module>
@import './assets/scss/variables';

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: var(--base-font-sans-serif);
  text-decoration: none;
  list-style: none;
}

.app {
  display: flex;

  .view {
    position: relative;
    width: 100%;
    min-height: 100vh;
    margin-left: 260px;

    @media screen and (max-width: $md) {
      margin-top: 80px;
      margin-left: 0;
    }

    &.isLoading {
      @keyframes dots-loader {
        0% {
          box-shadow: var(--color-danger-350-hex) -14px -14px 0 7px,
            var(--color-warning-350-hex) 14px -14px 0 7px,
            var(--color-success-350-hex) 14px 14px 0 7px,
            var(--color-secondary-350-hex) -14px 14px 0 7px;
        }

        8.33% {
          box-shadow: var(--color-danger-350-hex) 14px -14px 0 7px,
            var(--color-warning-350-hex) 14px -14px 0 7px,
            var(--color-success-350-hex) 14px 14px 0 7px,
            var(--color-secondary-350-hex) -14px 14px 0 7px;
        }

        16.67% {
          box-shadow: var(--color-danger-350-hex) 14px 14px 0 7px,
            var(--color-warning-350-hex) 14px 14px 0 7px,
            var(--color-success-350-hex) 14px 14px 0 7px,
            var(--color-secondary-350-hex) -14px 14px 0 7px;
        }

        25% {
          box-shadow: var(--color-danger-350-hex) -14px 14px 0 7px,
            var(--color-warning-350-hex) -14px 14px 0 7px,
            var(--color-success-350-hex) -14px 14px 0 7px,
            var(--color-secondary-350-hex) -14px 14px 0 7px;
        }

        33.33% {
          box-shadow: var(--color-danger-350-hex) -14px -14px 0 7px,
            var(--color-warning-350-hex) -14px 14px 0 7px,
            var(--color-success-350-hex) -14px -14px 0 7px,
            var(--color-secondary-350-hex) -14px -14px 0 7px;
        }

        41.67% {
          box-shadow: var(--color-danger-350-hex) 14px -14px 0 7px,
            var(--color-warning-350-hex) -14px 14px 0 7px,
            var(--color-success-350-hex) -14px -14px 0 7px,
            var(--color-secondary-350-hex) 14px -14px 0 7px;
        }

        50% {
          box-shadow: var(--color-danger-350-hex) 14px 14px 0 7px,
            var(--color-warning-350-hex) -14px 14px 0 7px,
            var(--color-success-350-hex) -14px -14px 0 7px,
            var(--color-secondary-350-hex) 14px -14px 0 7px;
        }

        58.33% {
          box-shadow: var(--color-danger-350-hex) -14px 14px 0 7px,
            var(--color-warning-350-hex) -14px 14px 0 7px,
            var(--color-success-350-hex) -14px -14px 0 7px,
            var(--color-secondary-350-hex) 14px -14px 0 7px;
        }

        66.67% {
          box-shadow: var(--color-danger-350-hex) -14px -14px 0 7px,
            var(--color-warning-350-hex) -14px -14px 0 7px,
            var(--color-success-350-hex) -14px -14px 0 7px,
            var(--color-secondary-350-hex) 14px -14px 0 7px;
        }

        75% {
          box-shadow: var(--color-danger-350-hex) 14px -14px 0 7px,
            var(--color-warning-350-hex) 14px -14px 0 7px,
            var(--color-success-350-hex) 14px -14px 0 7px,
            var(--color-secondary-350-hex) 14px -14px 0 7px;
        }

        83.33% {
          box-shadow: var(--color-danger-350-hex) 14px 14px 0 7px,
            var(--color-warning-350-hex) 14px -14px 0 7px,
            var(--color-success-350-hex) 14px 14px 0 7px,
            var(--color-secondary-350-hex) 14px 14px 0 7px;
        }

        91.67% {
          box-shadow: var(--color-danger-350-hex) -14px 14px 0 7px,
            var(--color-warning-350-hex) 14px -14px 0 7px,
            var(--color-success-350-hex) 14px 14px 0 7px,
            var(--color-secondary-350-hex) -14px 14px 0 7px;
        }

        100% {
          box-shadow: var(--color-danger-350-hex) -14px -14px 0 7px,
            var(--color-warning-350-hex) 14px -14px 0 7px,
            var(--color-success-350-hex) 14px 14px 0 7px,
            var(--color-secondary-350-hex) -14px 14px 0 7px;
        }
      }

      /* :not(:required) hides this rule from IE9 and below */
      .animatedLoader:not(:required) {
        position: absolute;
        top: 50%;
        left: 50%;
        display: inline-block;
        width: 7px;
        height: 7px;
        overflow: hidden;
        text-indent: -9999px;
        background: transparent;
        border-radius: 100%;
        box-shadow: var(--color-danger-350-hex) -14px -14px 0 7px,
          var(--color-warning-350-hex) 14px -14px 0 7px,
          var(--color-success-350-hex) 14px 14px 0 7px,
          var(--color-secondary-350-hex) -14px 14px 0 7px;
        transform-origin: 50% 50%;
        animation: dots-loader 5s infinite ease-in-out;
      }
    }
  }

  .viewContent {
    display: flex;
    flex-direction: column;
    gap: var(--base-spacing-6xl);
    width: 100%;
    max-width: 1700px;
    height: 100%;
    padding: 60px 180px;
    margin: auto;

    @media screen and (max-width: $xl) {
      padding: var(--base-spacing-6xl);
    }

    @media screen and (max-width: $md) {
      padding: var(--base-spacing-lg);
      margin-left: 0;
    }
  }
}
</style>
