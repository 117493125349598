<template>
  <UcAlert v-bind="{ ...$attrs, ...$props }" @click.native="$emit('click')">
    <template #title>
      <slot v-if="title" name="title">{{ title }}</slot>
    </template>
    <slot name="default">{{ body }}</slot>
  </UcAlert>
</template>

<script>
import { UcAlert } from '@unyc/common-components';

export default {
  name: 'BaseToast',
  components: {
    UcAlert,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    body: {
      type: String,
      default: null,
    },
    dismissable: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss">
$vt-color-default: transparent !default;
$vt-color-info: transparent !default;
$vt-color-success: transparent !default;
$vt-color-warning: transparent !default;
$vt-color-error: transparent !default;

@import 'vue-toastification/src/scss/_variables';
@import 'vue-toastification/src/scss/_toastContainer';
@import 'vue-toastification/src/scss/_toast';
@import 'vue-toastification/src/scss/animations/_bounce';
@import 'vue-toastification/src/scss/animations/_fade';

.#{$vt-namespace}__toast {
  padding: var(--base-spacing-xs) var(--base-spacing-xs);
  margin-bottom: var(--base-spacing-md);
  font-family: var(--base-font-sans-serif);
  border-radius: 0;
  box-shadow: none;
}

@import '~vue-toastification/dist/index.css';
</style>
