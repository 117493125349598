<template>
  <div :class="$style.baseBackLink" @click="goBack">
    <UcSVG name="arrow-left" width="12px" />
    <span>{{ label || $t('sharedInterface.return') }}</span>
  </div>
</template>

<script>
import { UcSVG } from '@unyc/common-components';

export default {
  name: 'BaseBackLink',
  components: {
    UcSVG,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
  },
  methods: {
    goBack() {
      this.hasHistory() ? this.$router.back() : this.$router.push('/');
    },
    hasHistory() {
      return window.history.length > 2;
    },
  },
};
</script>

<style lang="scss" module>
.baseBackLink {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  svg {
    margin-right: var(--base-spacing-xs);
  }
}
</style>
