<template>
  <div :class="$style.eventHistoryList">
    <div
      v-for="(groupedEvents, day) in groupedEventsByDay"
      :key="day"
      :class="$style.historyBlockDay"
    >
      <div :class="$style.day">{{ day }}</div>

      <div :class="$style.events">
        <EventHistoryListItem
          v-for="event in groupedEvents"
          :key="event.uuid"
          :event="event"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import EventHistoryListItem from './event-history-list-item.vue';

export default {
  name: 'EventHistoryList',
  components: {
    EventHistoryListItem,
  },
  props: {
    events: {
      type: Array,
      default: () => [],
    },
    totalCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState('status', ['mainCategories']),
    groupedEventsByDay() {
      const dateOptions = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };

      const { events } = this;
      const formatedEvents = events
        .sort((a, b) => b.createdAt - a.createdAt)
        .map((event) => ({
          ...event,
          formattedDate: new Date(event.createdAt).toLocaleDateString(
            this.$i18n.locale,
            dateOptions,
          ),
        }));

      return formatedEvents.reduce((result, currentItem) => {
        (result[currentItem['formattedDate']] =
          result[currentItem['formattedDate']] || []).push(currentItem);
        return result;
      }, {});
    },
  },
};
</script>

<style lang="scss" module>
@import '../assets/scss/variables';

.eventHistoryList {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--base-spacing-3xl);
  margin-top: var(--base-spacing-xxl);

  &:before {
    position: absolute;
    top: 10px;
    bottom: 0;
    display: flex;
    content: '';
    border: solid 1px var(--grey-100-hex);
  }

  .historyBlockDay {
    position: relative;
    padding-left: var(--base-spacing-6xl);

    .day {
      margin-bottom: var(--base-spacing-lg);
      font-size: 18px;
      font-weight: 500;
      text-transform: lowercase;

      &:first-letter {
        text-transform: uppercase;
      }

      &:before {
        position: absolute;
        top: 6px;
        left: 0;
        display: flex;
        width: 13px;
        height: 13px;
        margin-left: 1px;
        content: '';
        background-color: var(--grey-100-hex);
        border-radius: 50%;
        transform: translateX(-50%);
      }
    }

    .events {
      display: flex;
      flex-direction: column;
      row-gap: var(--base-spacing-lg);
    }

    @media screen and (max-width: $md) {
      padding-left: var(--base-spacing-xxl);
    }
  }
}
</style>
