<template>
  <div :class="$style.descriptions">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BaseDescriptions',
};
</script>

<style lang="scss" module>
.descriptions {
  display: flex;
  flex-wrap: wrap;
  row-gap: var(--base-spacing-3xl);
}
</style>
