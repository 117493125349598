import Vue from 'vue';
import axios from 'axios';

import VueMq from 'vue-mq';
import { UcSVGLoader } from '@unyc/common-components';
import Toast from './toast-plugin.js';

import store from './store';

import App from './app.vue';
import router from './router';
import { i18n } from './i18n';
import { statusApi } from './services/index';

import VueKeycloakJs from '@dsb-norge/vue-keycloak-js';

Vue.config.productionTip = false;
Vue.use(UcSVGLoader);
Vue.use(Toast);
Vue.use(VueMq, {
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
  },
});

const EventBus = new Vue();
export default EventBus;

const createVueApp = () => {
  new Vue({
    store,
    router,
    i18n,
    render: (h) => h(App),
  }).$mount('#app');
};

const apiVersionDefinitionURL =
  process.env.BASE_WELL_KNOWN_URL || '/.well-known/configuration';

axios
  .get(apiVersionDefinitionURL)
  .then((response) => {
    const apiObject = response.data.apis.find((api) => api.name === 'status');
    const iamObject = response.data.iam;
    const { endpoint } = apiObject;

    if (!endpoint) throw new Error('No endpoint definition was found');

    statusApi.defaults.baseURL = endpoint;
    Vue.use(VueKeycloakJs, {
      config: iamObject,
      init: { onLoad: 'check-sso' },
    });

    createVueApp();
  })
  .catch((err) => {
    console.error(err);
    alert(
      'During development, a fake ".well-known/configuration" URL is proxied. ' +
        'To allow this behaviour, please add a "well-known-configuration.json" ' +
        'file in "app" directory containing faked configuration to use (see ' +
        '"well-known-configuration.json.dist" example)',
    );
  });
