import axios from 'axios';
import Vue from 'vue';
import {
  transformResponse,
  transformRequest,
  paramsSerializer,
} from './utils/parsers';

export const statusApi = axios.create({
  headers: {
    Accept: 'application/x.unyc.status+json;version=1',
    'Content-Type': 'application/json',
  },
  transformResponse,
  transformRequest,
  paramsSerializer,
});

statusApi.interceptors.request.use(
  (config) => {
    if (Vue.prototype.$keycloak.authenticated) {
      config.headers[
        'Authorization'
      ] = `Bearer ${Vue.prototype.$keycloak.token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);
