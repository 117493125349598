var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{class:_vm.$style.blockSection},[_c('div',[_c('AlertName',{model:{value:(_vm.formValues.subscriptionName),callback:function ($$v) {_vm.$set(_vm.formValues, "subscriptionName", $$v)},expression:"formValues.subscriptionName"}}),_c('section',{class:[_vm.$style.section]},[_c('div',{class:_vm.$style.sectionName},[_vm._v(" "+_vm._s(_vm.$t('subscription.searchService'))+" ")]),_c('div',{class:[_vm.$style.sectionAction]},[_c('SearchServiceField',{model:{value:(_vm.formValues.selectedCategories),callback:function ($$v) {_vm.$set(_vm.formValues, "selectedCategories", $$v)},expression:"formValues.selectedCategories"}})],1)]),_c('AlertMail',{model:{value:(_vm.formValues.emailList),callback:function ($$v) {_vm.$set(_vm.formValues, "emailList", $$v)},expression:"formValues.emailList"}})],1)]),_c('div',{class:_vm.$style.blockSideBar},[_c('div',{class:_vm.$style.sideBar},[_c('div',{class:_vm.$style.formAlert},[_c('UcSVG',{attrs:{"origin":"most-webapp","name":"comment","type":"regular","height":"12px","width":"12px"}}),_c('span',{class:[
            _vm.formValues.subscriptionName
              ? _vm.$style.formAlertTitleColor
              : _vm.$style.formAlertTitle,
          ]},[_vm._v(" "+_vm._s(_vm.formValues.subscriptionName || _vm.$t('subscription.nameAlert'))+" ")])],1),_c('div',{class:_vm.$style.formAlert},[_c('UcSVG',{attrs:{"origin":"most-webapp","name":"box","type":"regular","height":"12px","width":"12px"}}),_c('span',{class:[
            _vm.formValues.selectedCategories.length
              ? _vm.$style.formAlertTitleColor
              : _vm.$style.formAlertTitle,
          ]},[_vm._v(_vm._s(_vm.$tc('subscription.service', _vm.numberOfSelectCategory, { count: _vm.numberOfSelectCategory, })))])],1),_c('div',{class:_vm.$style.formAlert},[_c('UcSVG',{attrs:{"origin":"most-webapp","name":"paper-plane","type":"regular","height":"12px","width":"12px"}}),_c('span',{class:[
            _vm.formValues.emailList
              ? _vm.$style.formAlertTitleColor
              : _vm.$style.formAlertTitle,
          ]},[_vm._v(" "+_vm._s(_vm.$tc('subscription.emailList', _vm.formValues.emailList, { count: _vm.formValues.emailList.length, })))])],1),_c('UcButton',{class:_vm.$style.subscriptionFormButton,attrs:{"size":"large","disabled":_vm.validateForm()},on:{"click":_vm.submitData}},[_vm._v(_vm._s(_vm.editMode ? _vm.$t('subscription.editAlert') : _vm.$t('subscription.subscribeToAlert')))])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }