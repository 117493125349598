export const EVENT_SEVERITY_ICON = Object.freeze({
  low: { color: 'warning', icon: { name: 'info-circle' } },
  medium: {
    color: 'warning',
    icon: { name: 'exclamation-circle', type: 'solid' },
  },
  high: {
    color: 'danger',
    icon: { name: 'cross-circle', type: 'solid' },
  },
});

export const UNKNOWN_EVENT_ICON = Object.freeze({
  color: 'primary',
  icon: { name: 'question-circle' },
});

export const TERMINATED_INCIDENT_EVENT_ICON = Object.freeze({
  color: 'success',
  icon: { name: 'task-done' },
});

export const SCHEDULED_EVENT_ICON = Object.freeze({
  color: 'secondary',
  icon: {
    name: 'maintenance-circle',
    type: 'solid',
  },
});

export const findEventStatus = (event) => {
  if (event.status === 'closed') {
    return TERMINATED_INCIDENT_EVENT_ICON;
  }
  if (event.type === 'scheduled') {
    return SCHEDULED_EVENT_ICON;
  }
  return EVENT_SEVERITY_ICON[event.severity] || UNKNOWN_EVENT_ICON;
};

export const EVENT_SEVERITY_DICT = Object.freeze({
  low: {
    color: 'primary',
    icon: { name: 'info-circle' },
    text: 'event.severityGrade.low',
  },
  medium: {
    color: 'warning',
    icon: { name: 'info-circle' },
    text: 'event.severityGrade.medium',
  },
  high: {
    color: 'danger',
    icon: { name: 'info-circle' },
    text: 'event.severityGrade.high',
  },
  UNKNOWN: { color: 'primary', icon: { name: 'info-circle' } },
});

export const findEventSeverity = (severity) =>
  EVENT_SEVERITY_DICT[severity] || EVENT_SEVERITY_DICT.unknown;
