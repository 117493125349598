<template>
  <BaseNavItem
    :key="category.uuid"
    :label="category.name"
    :to="{ name: 'Category', params: { uuid: category.uuid } }"
    :size="isMobile ? 'large' : 'medium'"
    @click="changeDisplayedMenu(false)"
  >
    <template #icon>
      <CategoryIllustrationIcon
        :category="category"
        :width="isMobile ? '20px' : '15px'"
        :height="isMobile ? '20px' : '15px'"
      />
    </template>
    <template #status>
      <div :class="[$style.statusDot, $style[statusDotColorClass]]"></div>
    </template>
  </BaseNavItem>
</template>
<script>
import BaseNavItem from './base-nav-item.vue';
import CategoryIllustrationIcon from './category-illustration-icon.vue';

import { mixinDetectingMobile } from '@/mixins';
import { findCategoryStatus } from '../lib/category.js';

export default {
  components: {
    BaseNavItem,
    CategoryIllustrationIcon,
  },
  mixins: [mixinDetectingMobile],
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  computed: {
    statusDotColorClass() {
      return findCategoryStatus(this.category.status).color;
    },
  },
};
</script>

<style lang="scss" module>
.statusDot {
  width: 6px;
  height: 6px;
  margin: auto 0 auto auto;
  background-color: white;
  background-color: currentColor;
  border-radius: 50%;

  &.primary {
    color: var(--color-primary-500-hex);
  }

  &.secondary {
    color: var(--color-secondary-500-hex);
  }

  &.success {
    color: var(--color-success-500-hex);
  }

  &.warning {
    color: var(--color-warning-500-hex);
  }

  &.danger {
    color: var(--color-danger-500-hex);
  }
}
</style>
