<template>
  <div :class="[$style.baseNavBar, { [$style.isMobile]: isMobile }]">
    <div :class="[$style.sidebar]">
      <div :class="$style.sidebarLogo">
        <slot name="logo" />
        <UcSVG
          v-if="!displayedMenu"
          origin="most-webapp"
          name="menu-bars"
          type="regular"
          width="19px"
          height="19px"
          :class="$style.iconMenu"
          @click="toggleDisplayMobileMenu"
        />
        <UcSVG
          v-if="displayedMenu"
          origin="most-webapp"
          name="cross"
          type="regular"
          width="19px"
          height="19px"
          :class="$style.iconMenu"
          @click="toggleDisplayMobileMenu"
        />
      </div>
      <div v-if="!isMobile || displayedMenu" :class="$style.sectionDashboard">
        <ul @click="toggleDisplayMobileMenu">
          <slot name="top-content" />
        </ul>
      </div>
      <div v-if="!isMobile || displayedMenu" :class="$style.sectionCategory">
        <span v-if="subtitle" :class="$style.subtitle">{{ subtitle }}</span>
        <ul @click="toggleDisplayMobileMenu">
          <slot name="bottom-content" />
        </ul>
      </div>
      <div v-if="!isMobile || displayedMenu" :class="$style.devider"></div>
      <div v-if="!isMobile || displayedMenu" :class="$style.sectionAction">
        <ul>
          <slot name="bottom-action" />
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { UcSVG } from '@unyc/common-components';
import { mixinDetectingMobile } from '@/mixins';

export default {
  name: 'BaseNavBar',
  components: { UcSVG },
  mixins: [mixinDetectingMobile],
  props: {
    subtitle: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      displayedMenu: false,
    };
  },
  methods: {
    toggleDisplayMobileMenu() {
      if (this.isMobile) {
        this.displayedMenu = !this.displayedMenu;
        this.$emit('displayed-menu', this.displayedMenu);
        this.$emit('click');
      }
    },
  },
};
</script>

<style lang="scss" module>
@import '../assets/scss/variables';

.baseNavBar {
  height: 100%;
  font-size: 13px;

  @media screen and (max-width: $md) {
    font-size: 16px;
  }

  .sidebar {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .devider {
      border: 1px solid #a4a4a4;

      @media screen and (max-width: $md) {
        display: none;
      }
    }

    .sectionAction {
      display: flex; //temporarly after go back to display: flex
      align-items: center;
      justify-content: center;
      margin: var(--base-spacing-xl) 0;
    }

    .subtitle {
      display: block;
      margin-top: 10px;
      text-transform: uppercase;
    }

    .iconMenu {
      display: none;
      margin: auto 0 auto auto;
      color: white;
      cursor: pointer;

      @media screen and (max-width: $md) {
        position: relative;
        top: 0;
        right: 0;
        display: flex;
      }
    }

    .sectionDashboard {
      margin: 0 var(--base-spacing-xs) auto var(--base-spacing-xs);
    }

    .sectionCategory {
      height: fit-content;
      margin: var(--base-spacing-xs);
      margin-bottom: var(--base-spacing-sm);

      .subtitle {
        margin-bottom: 10px;
        font-size: 10px;
        color: #797979;

        @media all and (max-width: $md) {
          margin-top: var(--base-spacing-4xl);
          margin-left: var(--base-spacing-sm);
          font-size: 1.3em;
        }
      }
    }

    .sidebarLogo {
      display: flex;
      padding: 25px;
    }

    ul {
      @media screen and (max-width: $md) {
        display: block !important;
        float: none;
        text-align: left;
      }
    }
  }

  &.isMobile {
    .sectionDashboard {
      margin-top: 0;
    }

    .sectionAction {
      margin-top: auto;
    }
  }
}
</style>
