import Vue from 'vue';
import VueI18n from 'vue-i18n';

import enUS from './locales/en-US.json';
import frFR from './locales/fr-FR.json';

const locales = ['en-US', 'fr-FR'];

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: locales[1],
  fallbackLocale: locales[0],
  messages: {
    'en-US': enUS,
    'fr-FR': frFR,
  },
  silentTranslationWarn: process.env.NODE_ENV === 'production',
});

function loadLanguage(lang) {
  if (i18n.locale === lang) {
    return;
  }
  const currentLang = locales.includes(lang) ? lang : 'en-US';
  i18n.setLocaleMessage(currentLang, locales[currentLang]);
  i18n.locale = currentLang;
  document.querySelector('html').setAttribute('lang', currentLang);
}

export { i18n, loadLanguage };
