<template>
  <div>
    <div :class="$style.blockSection">
      <div>
        <AlertName v-model="formValues.subscriptionName"></AlertName>
        <section :class="[$style.section]">
          <div :class="$style.sectionName">
            {{ $t('subscription.searchService') }}
          </div>
          <div :class="[$style.sectionAction]">
            <SearchServiceField
              v-model="formValues.selectedCategories"
            ></SearchServiceField>
          </div>
        </section>
        <!-- <AlertField v-model="form.alertType"></AlertField> -->
        <AlertMail v-model="formValues.emailList"></AlertMail>
      </div>
    </div>
    <div :class="$style.blockSideBar">
      <div :class="$style.sideBar">
        <div :class="$style.formAlert">
          <UcSVG
            origin="most-webapp"
            name="comment"
            type="regular"
            height="12px"
            width="12px"
          />
          <span
            :class="[
              formValues.subscriptionName
                ? $style.formAlertTitleColor
                : $style.formAlertTitle,
            ]"
          >
            {{ formValues.subscriptionName || $t('subscription.nameAlert') }}
          </span>
        </div>
        <div :class="$style.formAlert">
          <UcSVG
            origin="most-webapp"
            name="box"
            type="regular"
            height="12px"
            width="12px"
          />
          <span
            :class="[
              formValues.selectedCategories.length
                ? $style.formAlertTitleColor
                : $style.formAlertTitle,
            ]"
            >{{
              $tc('subscription.service', numberOfSelectCategory, {
                count: numberOfSelectCategory,
              })
            }}</span
          >
        </div>
        <!-- <div :class="$style.formAlert">
        <UcSVG
          origin="most-webapp"
          name="comment"
          type="regular"
          height="12px"
          width="12px"
        />
        <span :class="$style.formAlertTitle">{{
          $t('subscription.criticityLevel')
        }}</span>
      </div> -->
        <div :class="$style.formAlert">
          <UcSVG
            origin="most-webapp"
            name="paper-plane"
            type="regular"
            height="12px"
            width="12px"
          />
          <span
            :class="[
              formValues.emailList
                ? $style.formAlertTitleColor
                : $style.formAlertTitle,
            ]"
          >
            {{
              $tc('subscription.emailList', formValues.emailList, {
                count: formValues.emailList.length,
              })
            }}</span
          >
        </div>
        <UcButton
          :class="$style.subscriptionFormButton"
          size="large"
          :disabled="validateForm()"
          @click="submitData"
          >{{
            editMode
              ? $t('subscription.editAlert')
              : $t('subscription.subscribeToAlert')
          }}</UcButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import { UcButton, UcSVG } from '@unyc/common-components';
import SearchServiceField from '../components/subscriptions-form-search-service-field.vue';
import AlertName from './subscriptions-form-alert-name.vue';
import AlertMail from './subscriptions-form-alert-mail.vue';
import { statusApiCategoryService } from '../services/models/category';

export default {
  components: {
    UcButton,
    UcSVG,
    SearchServiceField,
    AlertName,
    AlertMail,
  },
  props: {
    formValues: {
      type: Object,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        alertType: [
          {
            id: 'critical',
            alert: this.$t('subscription.criticalAlert'),
            text: this.$t('subscription.criticalAlertText'),
            isChecked: false,
          },
          {
            id: 'warning',
            alert: this.$t('subscription.warningAlert'),
            text: this.$t('subscription.warningAlertText'),
            isChecked: false,
          },
          {
            id: 'info',
            alert: this.$t('subscription.okAlert'),
            text: this.$t('subscription.okAlertText'),
            isChecked: false,
          },
        ],
      },
      numberOfSelectCategory: null,
    };
  },
  computed: {
    selectedCategories() {
      return this.formValues.selectedCategories;
    },
  },
  watch: {
    selectedCategories() {
      this.updateSelectedCategoriesLenth();
    },
  },
  methods: {
    validateForm() {
      return !(
        this.formValues.emailList.length > 0 &&
        this.formValues.selectedCategories.length > 0 &&
        this.formValues.subscriptionName.length > 0
      );
    },
    submitData() {
      if (this.editMode === true) {
        this.formValues.formEdit = this.editMode;
        this.$emit('update-enable-value', this.formValues);
      } else {
        this.$emit('submit-data', this.formValues);
      }
    },
    updateSelectedCategoriesLenth() {
      let categories = [];
      statusApiCategoryService
        .list()
        .then((response) => {
          categories = response.data.results;
        })
        .finally(() => {
          const childCategories = categories
            .filter((category) => category.parentCategory != null)
            .map((c) => c.uuid);
          this.numberOfSelectCategory =
            this.formValues.selectedCategories.filter((sc) =>
              childCategories.includes(sc),
            ).length;
        });
    },
  },
};
</script>

<style lang="scss" module>
@import '../assets/scss/variables';

.blockSection {
  width: 70%;
}

.section {
  display: flex;
  align-items: center;
  padding: var(--base-spacing-5xl);
  background: var(--grey-0-hex);
  border-radius: var(--base-spacing-xxs);

  &:not(:first-child) {
    margin-top: var(--base-spacing-3xl);
  }

  &:last-child {
    align-items: baseline;
  }

  .sectionName {
    width: 180px;
  }

  .sectionAction {
    width: 60%;
    margin: 0 auto;
  }
}

.blockSideBar {
  width: 25%;

  .sideBar {
    position: sticky;
    top: var(--base-spacing-5xl);
    height: 500px;
    overflow-y: auto;

    .formAlert {
      display: flex;
      align-items: center;
      width: 100%;
      padding: var(--base-spacing-sm);
      background: white;
      border-radius: 4px;

      .formAlertTitleColor {
        padding: var(--base-spacing-xs);
        font-size: 14px;
        color: var(--base-font-color);
      }

      .formAlertTitle {
        padding: var(--base-spacing-xs);
        font-size: 14px;
        font-weight: 300;
        line-height: 16px;
        color: var(--grey-250-hex);
      }

      &:not(:first-child) {
        margin-top: var(--base-spacing-xs);
      }

      &:nth-last-child(2) {
        margin-bottom: var(--base-spacing-xs);
      }
    }

    .subscriptionFormButton {
      width: 100%;
    }
  }
}

@media screen and (max-width: $md) {
  .blockSection {
    width: 100%;
  }

  .section {
    .sectionName {
      margin: 0;
    }

    .sectionAction {
      width: 100%;
      margin: 0;
      margin-top: var(--base-spacing-md);
    }

    flex-direction: column;
    align-items: baseline;
  }

  .blockSideBar {
    width: 100%;

    .sideBar {
      position: unset;
      right: unset;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding-top: var(--base-spacing-3xl);
    }
  }
}

@media screen and (max-width: $xl) {
  .blockSection {
    width: 100%;
  }

  .blockSideBar {
    width: 100%;

    .sideBar {
      position: unset;
      right: unset;
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-top: var(--base-spacing-3xl);
    }
  }
}
</style>
