var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{class:[{ [_vm.$style.fullscreen]: _vm.displayedMenu && _vm.isMobile }, _vm.$style.nav]},[_c('BaseNavBar',{attrs:{"subtitle":_vm.$t('sharedInterface.ourServices')},on:{"displayed-menu":_vm.changeDisplayedMenu},scopedSlots:_vm._u([{key:"logo",fn:function(){return [_c('router-link',{attrs:{"to":"/"}},[_c('UcSVG',{style:({ color: _vm.platformHealthColor, cursor: 'pointer' }),attrs:{"origin":"most-webapp","name":"most","type":"brand-logo","height":"30px","width":"112px"}})],1)]},proxy:true},{key:"top-content",fn:function(){return [_c('BaseNavItem',{attrs:{"label":_vm.$t('sharedInterface.dashboard'),"to":{ name: 'Home' },"size":_vm.isMobile ? 'large' : 'medium',"icon":{
          origin: 'most-webapp',
          name: 'dashboard',
          type: 'regular',
        }},on:{"click":function($event){return _vm.changeDisplayedMenu(false)}}}),(_vm.$keycloak.authenticated)?_c('BaseNavItem',{attrs:{"label":_vm.$t('sharedInterface.mySubscription'),"to":{ name: 'Subscriptions' },"size":_vm.isMobile ? 'large' : 'medium',"icon":{
          origin: 'most-webapp',
          name: 'bell',
          type: 'regular',
        }}}):_vm._e()]},proxy:true},{key:"bottom-content",fn:function(){return _vm._l((_vm.mainCategories),function(category){return _c('li',{key:category.uuid,class:_vm.$style.navItemWrapper},[_c('NavItemCategory',{attrs:{"category":category}})],1)})},proxy:true},(_vm.$keycloak.ready)?{key:"bottom-action",fn:function(){return [(!_vm.$keycloak.authenticated)?_c('UcButton',{class:_vm.$style.buttonLogin,on:{"click":_vm.$keycloak.loginFn}},[_vm._v(" "+_vm._s(_vm.$t('common.login'))+" ")]):_c('UcButton',{class:_vm.$style.buttonLogin,on:{"click":_vm.$keycloak.logoutFn}},[_vm._v(" "+_vm._s(_vm.$t('common.logout'))+" ")])]},proxy:true}:null],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }