<template>
  <section :class="$style.eventHistoryBlock">
    <h2>{{ $t('eventHistoryBlock.eventsHistory') }}</h2>
    <template v-if="hasEvents">
      <EventHistoryList
        :events="events"
        :total-count="totalCount"
        @fetch-more-events="handleFetchMoreEvents"
      />
      <UcButton
        v-if="totalCount > 0 && totalCount > events.length"
        :class="$style.loadMoreEventsButton"
        @click="handleClickSeeMore"
      >
        {{ $t('sharedInterface.seeMore') }}
      </UcButton>
    </template>
    <div v-else :class="$style.historyPlaceholder">
      {{ $t('eventHistoryBlock.placeholder') }}
    </div>
  </section>
</template>

<script>
import EventHistoryList from './event-history-list.vue';
import { UcButton } from '@unyc/common-components';

export default {
  name: 'EventHistoryBlock',
  components: {
    EventHistoryList,
    UcButton,
  },
  props: {
    events: {
      type: Array,
      default: () => [],
    },
    totalCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    hasEvents() {
      return this.totalCount > 0;
    },
  },
  methods: {
    handleFetchMoreEvents() {
      this.$emit('fetch-more-events');
    },
    handleClickSeeMore() {
      this.$emit('fetch-more-events');
    },
    hasMoreEventsToFetch() {
      if (this.totalCount > 0 && this.totalCount > this.events.length) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" module>
.eventHistoryBlock {
  h2 {
    font-size: 22px;
    font-weight: 500;
  }

  .loadMoreEventsButton {
    width: fit-content;
    margin-top: 30px;
    margin-left: 43px;
  }

  .historyPlaceholder {
    margin-top: var(--base-spacing-sm);
    font-size: 16px;
    color: var(--grey-300-hex);
  }
}
</style>
