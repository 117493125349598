<template>
  <BaseCard>
    <template #header>
      <div :class="$style.header">
        <EventStatusIcon :event="event" />

        <span :class="$style.heading">{{ event.description }}</span>

        <span :class="$style.subHeading">
          -
          {{ businessServicesName }}
        </span>
      </div>
    </template>
    <BaseDescriptions>
      <BaseDescriptionItem
        :label="$t('event.category')"
        :class="$style.baseDescriptionItem"
      >
        {{ categoryName || '-' }}
      </BaseDescriptionItem>

      <BaseDescriptionItem
        :label="$t('event.subcategory')"
        :class="$style.baseDescriptionItem"
      >
        {{ subcategoryNames || '-' }}
      </BaseDescriptionItem>

      <BaseDescriptionItem
        :label="$t('event.type')"
        :class="$style.baseDescriptionItem"
      >
        {{ event._type || '-' }}
      </BaseDescriptionItem>

      <BaseDescriptionItem
        :label="$t('event.severity')"
        :class="$style.baseDescriptionItem"
      >
        {{ eventSeverity.text ? $t(eventSeverity.text) : '-' }}
      </BaseDescriptionItem>

      <BaseDescriptionItem
        :label="$t('event.service')"
        :class="$style.baseDescriptionItem"
      >
        {{ impactedBusinessServices }}
      </BaseDescriptionItem>

      <BaseDescriptionItem
        :label="$t('event.status')"
        :class="$style.baseDescriptionItem"
      >
        {{ event._status ? $t(`eventStatus.${event._status}`) : '-' }}
      </BaseDescriptionItem>

      <BaseDescriptionItem
        :label="$t('event.startingDate')"
        :class="$style.baseDescriptionItem"
      >
        {{ scheduledStartingDate || '-' }}
      </BaseDescriptionItem>

      <BaseDescriptionItem
        :label="$t('event.endingDate')"
        :class="$style.baseDescriptionItem"
      >
        {{ scheduledClosingDate || '-' }}
      </BaseDescriptionItem>
    </BaseDescriptions>
  </BaseCard>
</template>

<script>
import BaseCard from './base-card.vue';
import BaseDescriptionItem from './base-description-item.vue';
import BaseDescriptions from './base-descriptions.vue';

import EventStatusIcon from './event-status-icon.vue';

import { findEventSeverity } from '../lib/event.js';

export default {
  name: 'EventDetailsCard',
  components: {
    BaseCard,
    BaseDescriptions,
    BaseDescriptionItem,
    EventStatusIcon,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  computed: {
    businessServicesName() {
      const businessServices = this.event._technicalService._businessServices;
      if (businessServices.length == 1) {
        return businessServices[0].name;
      }
      return `${businessServices.length} ${this.$t('event.impactedServices')}`;
    },
    categoryName() {
      const businessServices = this.event._technicalService._businessServices;
      return this.removeDuplicatedValues(
        businessServices
          .map((bs) => {
            return bs._serviceCategories.map((sc) => {
              return sc._category._parentCategory === null
                ? sc._category.name
                : sc._category._parentCategory.name;
            });
          })
          .filter(String)
          .flat(),
      ).join(', ');
    },
    subcategoryNames() {
      const businessServices = this.event._technicalService._businessServices;
      return this.removeDuplicatedValues(
        businessServices
          .map((bs) => {
            return bs._serviceCategories
              .filter((sc) => sc._category._parentCategory)
              .map((sc) => sc._category.name);
          })
          .filter(String)
          .flat(),
      ).join(', ');
    },
    impactedBusinessServices() {
      const businessServices = this.event._technicalService._businessServices;
      return this.removeDuplicatedValues(
        businessServices
          .map((bs) => {
            return bs.name;
          })
          .filter(String)
          .flat(),
      ).join(', ');
    },
    scheduledStartingDate() {
      const { scheduledStartingDate } = this.event;
      return this.formatDateTime(scheduledStartingDate);
    },
    scheduledClosingDate() {
      const { scheduledClosingDate } = this.event;
      return this.formatDateTime(scheduledClosingDate);
    },
    eventSeverity() {
      return findEventSeverity(this.event._severity.toLowerCase());
    },
  },
  methods: {
    formatDateTime(dateTime) {
      if (!dateTime) return null;

      const dateFormat = {
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      };

      return new Date(dateTime).toLocaleString(this.$i18n.locale, dateFormat);
    },
    removeDuplicatedValues(data) {
      return data.filter((value, index) => data.indexOf(value) == index);
    },
  },
};
</script>

<style lang="scss" module>
@import '../assets/scss/variables';

.header {
  display: flex;
  align-items: center;
}

.heading {
  margin-left: var(--base-spacing-sm);
  font-weight: 500;
}

.subHeading {
  margin-left: var(--base-spacing-xxs);
  font-weight: 300;
}

.baseDescriptionItem {
  flex: 25%;

  @media all and (max-width: $lg) {
    flex: 50%;
  }

  @media all and (max-width: $md) {
    flex: 100%;
  }
}
</style>
