import { statusApi } from '../index';

const resource = '/categories/';

export const statusApiCategoryService = {
  list(params) {
    return statusApi.get(resource, { params });
  },
  get(uuid) {
    return statusApi.get(`${resource}${uuid}/`);
  },
};
