import axios from 'axios';
import humps from 'humps';
import qs from 'qs';

// Parses responses keys to camelCase except for capital OPTIONS keys i.e: _PUT
// also, `_` prefixed keys like _display_values are left  with their _ prefix
const transformResponse = [
  ...axios.defaults.transformResponse,
  (data, headers) =>
    data && /json/.test(headers['content-type'])
      ? humps.camelizeKeys(data, (key, convert) => {
          const isOptionKey = /^[A-Z0-9_]+$/.test(key);
          if (isOptionKey) return key;

          const isDisplayNameKey = key.charAt(0) === '_';
          if (isDisplayNameKey) return '_' + convert(key);

          return convert(key);
        })
      : data,
];

// Parses payloads keys to Python API snake_case except for form-data
const transformRequest = [
  (data, headers) => {
    return data && headers['Content-Type'] === 'multipart/form-data'
      ? data
      : humps.decamelizeKeys(data, (key, convert, options) =>
          /^[A-Z0-9_]+$/.test(key) ? key : convert(key, options),
        );
  },
  ...axios.defaults.transformRequest,
];

const paramsSerializer = (params) =>
  qs.stringify(params, { arrayFormat: 'repeat' });

export { transformResponse, transformRequest, paramsSerializer };
