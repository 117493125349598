<template>
  <div :class="$style.categoryParentCard">
    <CategoryIllustrationIcon
      v-if="category && category.icon"
      :category="category"
      height="50px"
      width="50px"
      circle
    />

    <div :class="$style.info">
      <div :class="$style.name">{{ category.name }}</div>
      <div :class="$style.status">{{ category._status }}</div>
    </div>
  </div>
</template>

<script>
import CategoryIllustrationIcon from './category-illustration-icon.vue';

export default {
  name: 'CategoryParentCard',
  components: { CategoryIllustrationIcon },
  props: {
    category: {
      type: Object,
      required: true,
      validator: (obj) => {
        return obj.name && obj.uuid && obj.status && obj._status;
      },
    },
  },
};
</script>

<style lang="scss" module>
.categoryParentCard {
  display: flex;
  align-items: center;
  padding: var(--base-spacing-xl);
  background-color: var(--base-background-color);
  border-radius: 4px;
  box-shadow: var(--base-shadow-sm);

  .info {
    margin-left: var(--base-spacing-md);

    .name {
      font-size: 22px;
      font-weight: 500;
      line-height: 1.82;
    }

    .status {
      margin-top: -8px;
      font-size: 12px;
      font-weight: 300;
      line-height: 1.67;
      color: var(--grey-250-hex);
    }
  }
}
</style>
