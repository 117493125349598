<template>
  <div v-show="eventAlerts.length" :class="$style.eventAlertsList">
    <UcAlert
      v-for="event in eventAlerts"
      :key="event.uuid"
      color="secondary"
      :icon="{ name: 'maintenance-circle', origin: 'most-webapp' }"
    >
      {{
        `${$t('maintenanceAlert.title', {
          startingDate: new Date(
            event.scheduledStartingDate,
          ).toLocaleDateString(navigatorLocale, dateDisplayConfiguration),
          startingHour: new Date(
            event.scheduledStartingDate,
          ).toLocaleTimeString(navigatorLocale, timeDisplayConfiguration),
        })}`
      }}
      <template #action>
        <router-link :class="$style.seeEventLink" :to="`/event/${event.uuid}`">
          <UcHyperlink>
            {{ $t('event.alert.readMore') }}
          </UcHyperlink>
        </router-link>
      </template>
    </UcAlert>
  </div>
</template>

<script>
import { UcAlert, UcHyperlink } from '@unyc/common-components';

const LOCAL_STORAGE_SLOT = 'most-dismissed-events';

export default {
  name: 'EventsAlertsList',
  components: {
    UcAlert,
    UcHyperlink,
  },
  props: {
    events: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data() {
    return {
      navigatorLocale: window.navigator.language,
      dateDisplayConfiguration: {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      },
      timeDisplayConfiguration: { hour: '2-digit', minute: '2-digit' },
      dismissedEventsUuids: [],
    };
  },
  computed: {
    eventAlerts() {
      return this.events.filter(this.isScheduledEvent);
    },
  },
  watch: {
    dismissedEventsUuids(newValue) {
      localStorage.setItem(LOCAL_STORAGE_SLOT, JSON.stringify(newValue));
    },
  },
  mounted() {
    this.loadDismissedEventsFromLocalStorage();
  },
  methods: {
    loadDismissedEventsFromLocalStorage() {
      const eventsUuidInStore = localStorage.getItem(LOCAL_STORAGE_SLOT);
      if (!eventsUuidInStore) return;

      this.dismissedEventsUuids = JSON.parse(eventsUuidInStore);
    },
    addToDismissedEvents(uuid) {
      this.dismissedEventsUuids.push(uuid);
    },
    isScheduledEvent(event) {
      return event.type === 'scheduled';
    },
  },
};
</script>

<style lang="scss" module>
.eventAlertsList {
  display: flex;
  flex-direction: column;
  gap: var(--base-spacing-lg);

  .seeEventLink {
    text-decoration: none;
  }
}
</style>
