<template>
  <div :class="$style.baseSeparator" />
</template>

<script>
export default {
  name: 'BaseSeparator',
};
</script>

<style lang="scss" module>
.baseSeparator {
  height: 1px;
  background-color: var(--grey-100-hex);
}
</style>
