<template>
  <div>
    <div
      v-for="subEvent in subscriptions"
      :key="subEvent.uuid"
      :class="$style.notificationsServiceBlock"
    >
      <div :class="$style.notificationServiceName">
        {{ subEvent._event.description }}
      </div>
      <div :class="$style.numberOfSubscriber">
        <div :class="$style.numberOfSubscriberTitle">
          {{ $t('subscriptionCenterBody.numberOfSubscriberTtile') }}
        </div>
        <div :class="$style.numberOfSubscriberBody">
          {{
            $tc(
              'subscriptionCenterBody.numberOfSubscriberBody',
              subEvent.emails.length,
              { count: subEvent.emails.length },
            )
          }}
        </div>
      </div>
      <div :class="$style.numberOfSubscriber">
        <div :class="$style.numberOfSubscriberTitle">
          {{ $t('subscriptionCenterBody.numberOfServiceTitle') }}
        </div>
        <div :class="$style.numberOfSubscriberBody">
          {{ businessServicesName(subEvent._event) }}
        </div>
      </div>
      <div :class="$style.blockManage">
        <UcChoice
          type="switch"
          color="success"
          :value="subEvent.enable"
          @input="updateSubscriptionService(subEvent, $event)"
        ></UcChoice>
        <UcDropdownMenu>
          <UcSVG
            slot="button"
            origin="most-webapp"
            name="menu-dots"
            type="regular"
            height="13px"
            width="16px"
            :class="$style.managementIcon"
            @click="openModal"
          ></UcSVG>
          <UcDropdownMenuItem
            id="view"
            :icon="{ name: 'eye', type: 'regular' }"
            @click="goTo(subEvent._event)"
          >
            {{ $t('subscriptionCenterBody.dropDownMenuView') }}
          </UcDropdownMenuItem>
          <UcDropdownMenuItem
            id="delete"
            :icon="{ name: 'trash', type: 'regular' }"
            color="danger"
            @click="deleteSubscription(subEvent.uuid)"
          >
            {{ $t('subscriptionCenterBody.dropDownMenuDelete') }}
          </UcDropdownMenuItem>
        </UcDropdownMenu>
      </div>
    </div>
  </div>
</template>

<script>
import {
  UcChoice,
  UcSVG,
  UcDropdownMenu,
  UcDropdownMenuItem,
} from '@unyc/common-components';
import { mixinModal } from '../mixins';

export default {
  components: {
    UcChoice,
    UcSVG,
    UcDropdownMenu,
    UcDropdownMenuItem,
  },
  mixins: [mixinModal],
  props: {
    subscriptions: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    goTo(event) {
      this.$router.push(this.eventUrl(event));
    },
    updateSubscriptionService(subEvent, value) {
      subEvent.enable = value;
      this.$emit('update-enable-value', subEvent);
    },
    deleteSubscription(subscriptionId) {
      this.$emit('delete-subscription', subscriptionId);
    },
    businessServicesName(event) {
      const businessServices = event._technicalService._businessServices;
      if (businessServices.length == 1) {
        return businessServices[0].name;
      }
      return `${businessServices.length} ${this.$t('event.impactedServices')}`;
    },
    eventUrl(event) {
      return {
        name: 'Event',
        params: { uuid: event.uuid },
      };
    },
  },
};
</script>

<style lang="scss" module>
@import '../assets/scss/variables';

.notificationsServiceBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--base-spacing-lg) var(--base-spacing-xs);
  margin-top: var(--base-spacing-6xl);
  border-radius: 4px;
  box-shadow: var(--base-shadow-sm);

  .notificationServiceName {
    width: 150px;
    font-size: var(--base-spacing-md);
    font-weight: 500;
  }

  .heading {
    font-weight: 500;
    color: var(--base-font-color);
  }

  .numberOfSubscriber {
    display: grid;

    .numberOfSubscriberTitle {
      font-size: var(--base-spacing-sm);
      font-weight: 500;
    }

    .numberOfSubscriberBody {
      font-size: var(--base-spacing-sm);
      font-weight: 300;
    }
  }

  .blockManage {
    display: flex;
    align-items: center;

    .managementIcon {
      margin-left: var(--base-spacing-xl);
      cursor: pointer;
    }
  }
}
</style>
