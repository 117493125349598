<template>
  <div :class="$style.categoryChildCard">
    <CategoryStatusIcon :status="category.status" :class="$style.icon" />
    <span>{{ category.name }}</span>
    <InformativTooltip v-if="category.message" :message="category.message" />
  </div>
</template>

<script>
import CategoryStatusIcon from './category-status-icon.vue';
import InformativTooltip from './informativ-tooltip.vue';

export default {
  name: 'CategoryChildCard',
  components: { CategoryStatusIcon, InformativTooltip },
  props: {
    category: {
      type: Object,
      required: true,
      validator: (obj) => {
        return obj.name && obj.uuid && obj.status && obj._status;
      },
    },
  },
};
</script>

<style lang="scss" module>
.categoryChildCard {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  padding: var(--base-spacing-xl);
  font-size: 18px;
  border-radius: 4px;
  box-shadow: var(--base-shadow-sm);
}
</style>
