<template>
  <div>
    <subscriptionsCenter></subscriptionsCenter>
  </div>
</template>

<script>
import subscriptionsCenter from '../components/subscriptions-center.vue';
export default {
  name: 'Subscriptions',
  components: {
    subscriptionsCenter,
  },
};
</script>
