<template>
  <section :class="[$style.section]">
    <div :class="$style.sectionName">
      {{ $t('subscription.whoAlerted') }}
    </div>
    <div :class="[$style.sectionAction]">
      <UcInput
        v-model="newEmail"
        input="email"
        :class="$style.inputEmail"
        :errors="inputErrors"
        @keyup.enter="addEmail"
      >
      </UcInput>
      <div :class="$style.emailList">
        <div
          v-for="(email, index) in value"
          :key="index"
          :class="$style.emailItem"
        >
          {{ email }}
          <UcSVG
            origin="most-webapp"
            name="trash"
            type="regular"
            height="13px"
            width="16px"
            @click="removeEmail(index)"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { UcInput, UcSVG } from '@unyc/common-components';

export default {
  components: {
    UcInput,
    UcSVG,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      newEmail: '',
      inputErrors: [],
    };
  },
  methods: {
    addEmail() {
      if (
        this.isValidEmail(this.newEmail) &&
        !this.value.includes(this.newEmail)
      ) {
        this.value.push(this.newEmail);
        this.newEmail = '';
        this.inputErrors = [];
      } else {
        this.inputErrors = [this.$t('subscription.popupInvalidMail')];
      }
    },
    isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    removeEmail(index) {
      this.value.splice(index, 1);
    },
  },
};
</script>
<style lang="scss" module>
@import '../assets/scss/variables';

.section {
  display: flex;
  align-items: center;
  padding: var(--base-spacing-5xl);
  background: var(--grey-0-hex);
  border-radius: var(--base-spacing-xxs);

  &:not(:first-child) {
    margin-top: var(--base-spacing-3xl);
  }

  &:last-child {
    align-items: baseline;
  }

  .sectionName {
    width: 180px;
  }

  .sectionAction {
    width: 60%;
    margin: 0 auto;

    .inputEmail {
      width: 100%;
    }

    .popup {
      width: 100%;
      padding: var(--base-spacing-xs);
      margin: var(--base-spacing-xs) 0;
      font-size: var(--base-spacing-sm);
      color: var(--color-danger-500-hex);
      border: 1px solid var(--grey-100-hex);
      border-radius: var(--base-spacing-xxs);
    }
  }
}

.emailItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: var(--base-spacing-xs);
  margin: var(--base-spacing-xs) 0;
  background: var(--grey-0-hex);
  border: 1px solid var(--grey-100-hex);
  border-radius: 4px;
}

@media screen and (max-width: $md) {
  .section {
    .sectionName {
      margin: 0;
    }

    .sectionAction {
      width: 100%;
      margin: 0;
      margin-top: var(--base-spacing-md);
    }

    flex-direction: column;
    align-items: baseline;
  }
}
</style>
