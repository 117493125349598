<template>
  <div :class="$style.eventHistoryComment">
    <div :class="$style.content">
      <span :class="$style.subject">{{ status }} -</span>
      <span :class="$style.message">{{ content }}</span>
    </div>

    <div :class="$style.date">
      {{ formattedDate }}
    </div>

    <slot />
  </div>
</template>

<script>
export default {
  name: 'EventHistoryComment',
  props: {
    content: {
      type: String,
      required: true,
    },
    createdAt: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    formattedDate() {
      const { createdAt } = this;
      if (!createdAt) return '-';

      const dateFormat = {
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      };
      return new Date(createdAt).toLocaleString(this.$i18n.locale, dateFormat);
    },
  },
};
</script>

<style lang="scss" module>
.eventHistoryComment {
  display: flex;
  flex-direction: column;

  .content {
    display: block;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.43;

    .subject,
    .message {
      display: inline;
    }

    .subject {
      font-weight: 500;
    }

    .message {
      margin-left: var(--base-spacing-xxs);
    }
  }

  .date {
    margin-top: var(--base-spacing-xxs);
    font-size: 12px;
    font-weight: 300;
    line-height: 1.67;
    color: var(--grey-250-hex);
  }
}
</style>
