import { actions } from './actions';
import { mutations } from './mutations';

const state = {
  mainCategories: [],
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
