<template>
  <base-circled-icon v-bind="iconProps" />
</template>

<script>
import { findCategoryStatus } from '../lib/category.js';

import BaseCircledIcon from './base-circled-icon.vue';

export default {
  name: 'CategoryIllustrationIcon',
  components: { BaseCircledIcon },
  props: {
    category: {
      type: Object,
      required: true,
      validator: (obj) => {
        return obj.status && obj._status && obj.icon;
      },
    },
    circle: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '20px',
    },
    height: {
      type: String,
      default: '20px',
    },
    wrapperWidth: {
      type: String,
      default: '18px',
    },
    wrapperHeight: {
      type: String,
      default: '18px',
    },
  },
  computed: {
    iconProps() {
      const { color } = findCategoryStatus(this.category.status);
      return {
        ...this.$props,
        iconSrc: this.category.icon,
        color,
      };
    },
  },
};
</script>
