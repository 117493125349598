<template>
  <section :class="[$style.section]">
    <div :class="$style.sectionName">
      {{ $t('subscription.addName') }}
    </div>
    <UcInput
      v-model="value"
      :label="$t('subscription.nameAlert')"
      :required="true"
      :placeholder="$t('subscription.enterNameAlert')"
      :class="$style.sectionAction"
      maxlength="45"
    ></UcInput>
  </section>
</template>
<script>
import { UcInput } from '@unyc/common-components';

export default {
  components: {
    UcInput,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  watch: {
    value(currentValue) {
      this.$emit('input', currentValue);
    },
  },
};
</script>
<style lang="scss" module>
@import '../assets/scss/variables';

.blockSection {
  width: 70%;
}

.section {
  display: flex;
  align-items: center;
  padding: var(--base-spacing-5xl);
  background: var(--grey-0-hex);
  border-radius: var(--base-spacing-xxs);

  &:not(:first-child) {
    margin-top: var(--base-spacing-3xl);
  }

  &:last-child {
    align-items: baseline;
  }

  .sectionName {
    width: 180px;
  }

  .sectionAction {
    width: 60%;
    margin: 0 auto;
  }
}

.sideBar {
  display: flex;
  flex-direction: column;
  width: 25%;

  .formAlert {
    display: flex;
    align-items: center;
    width: 100%;
    padding: var(--base-spacing-sm);
    background: white;
    border-radius: var(--base-spacing-xxs);

    &:not(:first-child) {
      margin-top: var(--base-spacing-xs);
    }

    &:nth-last-child(2) {
      margin-bottom: var(--base-spacing-xs);
    }
  }
}

@media screen and (max-width: $md) {
  .blockSection {
    width: 100%;
  }

  .section {
    .sectionName {
      margin: 0;
    }

    .sectionAction {
      width: 100%;
      margin: 0;
      margin-top: var(--base-spacing-md);
    }

    flex-direction: column;
    align-items: baseline;
  }

  .sideBar {
    align-items: center;
    width: 100%;
    padding-top: var(--base-spacing-3xl);
  }
}

@media screen and (max-width: $xl) {
  .blockSection {
    width: 100%;
  }

  .sideBar {
    width: 100%;
    padding-top: var(--base-spacing-3xl);
  }
}
</style>
