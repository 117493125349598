<template>
  <nav
    :class="[{ [$style.fullscreen]: displayedMenu && isMobile }, $style.nav]"
  >
    <BaseNavBar
      :subtitle="$t('sharedInterface.ourServices')"
      @displayed-menu="changeDisplayedMenu"
    >
      <template #logo>
        <router-link to="/">
          <UcSVG
            origin="most-webapp"
            name="most"
            type="brand-logo"
            height="30px"
            width="112px"
            :style="{ color: platformHealthColor, cursor: 'pointer' }"
          />
        </router-link>
      </template>
      <template #top-content>
        <BaseNavItem
          :label="$t('sharedInterface.dashboard')"
          :to="{ name: 'Home' }"
          :size="isMobile ? 'large' : 'medium'"
          :icon="{
            origin: 'most-webapp',
            name: 'dashboard',
            type: 'regular',
          }"
          @click="changeDisplayedMenu(false)"
        />
        <BaseNavItem
          v-if="$keycloak.authenticated"
          :label="$t('sharedInterface.mySubscription')"
          :to="{ name: 'Subscriptions' }"
          :size="isMobile ? 'large' : 'medium'"
          :icon="{
            origin: 'most-webapp',
            name: 'bell',
            type: 'regular',
          }"
        />
      </template>

      <template #bottom-content>
        <li
          v-for="category in mainCategories"
          :key="category.uuid"
          :class="$style.navItemWrapper"
        >
          <NavItemCategory :category="category"></NavItemCategory>
        </li>
      </template>
      <template v-if="$keycloak.ready" #bottom-action>
        <UcButton
          v-if="!$keycloak.authenticated"
          :class="$style.buttonLogin"
          @click="$keycloak.loginFn"
        >
          {{ $t('common.login') }}
        </UcButton>
        <UcButton
          v-else
          :class="$style.buttonLogin"
          @click="$keycloak.logoutFn"
        >
          {{ $t('common.logout') }}
        </UcButton>
      </template>
    </BaseNavBar>
  </nav>
</template>
<script>
require('default-passive-events');
import { mixinDetectingMobile } from '@/mixins';
import { UcSVG, UcButton } from '@unyc/common-components';
import {
  getMostCriticalStatusFromCategories,
  findCategoryStatus,
} from '../lib/category.js';

import BaseNavBar from './base-nav-bar.vue';
import BaseNavItem from './base-nav-item.vue';

import NavItemCategory from './nav-item-category.vue';

export default {
  name: 'NavBar',
  components: {
    UcSVG,
    BaseNavBar,
    BaseNavItem,
    NavItemCategory,
    UcButton,
  },
  mixins: [mixinDetectingMobile],
  props: {
    mainCategories: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      displayedMenu: false,
    };
  },
  computed: {
    platformHealthColor() {
      const status = getMostCriticalStatusFromCategories(this.mainCategories);
      const { color } = findCategoryStatus(status);

      return `var(--color-${color}-500-hex)`;
    },
  },
  mounted() {
    document.title = 'most';
  },
  methods: {
    changeDisplayedMenu(value) {
      this.displayedMenu = value;
      this.$emit('displayed-menu', value);
    },
  },
};
</script>

<style lang="scss" module>
@import '../assets/scss/variables';

.buttonLogin {
  width: 226px;
  height: 37px;
  border: 1px solid #fff !important;
}

.modalContentText {
  font-family: Orkney, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  text-align: center;
}

.inputEmail {
  text-align: unset !important;
}

.nav {
  position: fixed;
  width: 260px;
  height: 100%;
  overflow: hidden;
  background: var(--base-font-color);

  @media screen and (max-width: $md) {
    width: 100% !important;
    height: 80px !important;
    transform: translate(0);

    &.fullscreen {
      height: 100% !important;
      transform: none;
    }
  }
}
</style>
