<template>
  <div :class="$style.home">
    <button v-if="modalVisible" @click="showUnsubscribeModal">test</button>
    <Subscription
      v-if="$route.name == 'Subscription'"
      :uuid="$route.params.uuid"
    />
    <section :class="$style.platformStateSummary">
      <CategoryStatusIcon
        :status="categoriesMostCriticalStatus"
        width="40px"
        height="40px"
      />
      <div :class="$style.heading">
        <span> {{ plateformStatusSummary }} </span>
      </div>
    </section>

    <EventsAlertsList :events="alert.items" />

    <section :class="$style.categoriesGridSection">
      <router-link
        v-for="category in mainCategories"
        :key="category.uuid"
        :to="{ name: 'Category', params: { uuid: category.uuid } }"
      >
        <CategoryStatusBlock
          :category="category"
          :direction="isMobile ? 'row' : 'column'"
        />
      </router-link>
    </section>

    <section>
      <CurrentEventsList
        v-if="currentEvents.length"
        :events="currentEvents"
      ></CurrentEventsList>
    </section>

    <EventHistoryBlock
      :events="event.items"
      :total-count="event.count"
      @fetch-more-events="fetchClosedEventsNextPage"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import { statusApiEventService } from '../services/models/event.js';
import { getMostCriticalStatusFromCategories } from '../lib/category.js';

import CategoryStatusIcon from '../components/category-status-icon.vue';
import CategoryStatusBlock from '../components/category-status-block.vue';
import EventsAlertsList from '../components/events-alerts-list.vue';
import EventHistoryBlock from '../components/event-history-block.vue';
import CurrentEventsList from '../components/current-events-list.vue';

import { mixinDetectingMobile, mixinIsPage } from '@/mixins.js';

export default {
  name: 'Home',
  components: {
    CategoryStatusBlock,
    CategoryStatusIcon,
    EventsAlertsList,
    EventHistoryBlock,
    CurrentEventsList,
  },
  mixins: [mixinDetectingMobile, mixinIsPage],
  props: {
    modalVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentEvents: [],
      event: {
        count: 0,
        items: [],
        limit: 5,
        offset: 0,
      },
      alert: {
        count: 0,
        items: [],
        limit: 3,
        offset: 0,
      },
    };
  },
  computed: {
    ...mapState('status', ['mainCategories']),
    categoriesMostCriticalStatus() {
      return getMostCriticalStatusFromCategories(this.mainCategories);
    },
    plateformStatusSummary() {
      const { categoriesMostCriticalStatus } = this;
      const categoriesTranslationsDict = {
        up: 'allServicesAreUp',
        maintenance: 'someServicesAreUnderMaintenance',
        partial: 'partialImpact',
        critical: 'situationIscritical',
        down: 'someServicesAreDown',
        unknown: 'unknown',
      };

      const count = this.mainCategories.filter((c) => {
        return c.status == categoriesMostCriticalStatus;
      }).length;

      return this.$tc(
        `platformStates.${categoriesTranslationsDict[categoriesMostCriticalStatus]}`,
        count > 1 ? 0 : 1,
      );
    },
  },
  methods: {
    fetchClosedEvents() {
      const params = {
        status: 'closed',
        limit: this.event.limit,
        offset: this.event.offset,
      };

      return statusApiEventService.list(params).then((response) => {
        this.event.items = this.event.items.concat(response.data.results);
        this.event.count = response.data.count;
      });
    },
    fetchClosedEventsNextPage() {
      this.event.offset += this.event.limit;
      this.fetchClosedEvents();
    },
    fetchCurrentEvents() {
      const params = {
        type: ['incident'],
        ['exclude_status']: 'closed',
      };
      return statusApiEventService.list(params).then((response) => {
        this.currentEvents = response.data.results;
      });
    },
    fetchEventsAlerts() {
      const params = {
        ['exclude_status']: 'closed',
        limit: this.alert.limit,
        offset: this.alert.offset,
      };
      return statusApiEventService.list(params).then((response) => {
        this.alert.items = this.alert.items.concat(response.data.results);
        this.alert.count = response.data.count;
      });
    },
    async mountedBeforeCallback() {
      await this.fetchClosedEvents();
      await this.fetchEventsAlerts();
      await this.fetchCurrentEvents();
    },
  },
};
</script>

<style lang="scss" module>
@import '../assets/scss/variables';

.home {
  .platformStateSummary {
    display: flex;
    flex-direction: column;
    align-items: center;

    .heading {
      margin-top: var(--base-spacing-3xl);
      font-size: 30px;
      font-weight: 300;
      line-height: 1.33;
      text-align: center;
    }
  }

  .categoriesGridSection {
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;

    @media screen and (max-width: $lg) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: $sm) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
</style>
