<template>
  <div :class="$style.card">
    <div v-if="!!$slots.header" :class="$style.header">
      <slot name="header" />
    </div>

    <BaseSeparator />

    <div :class="$style.body">
      <slot />
    </div>
  </div>
</template>

<script>
import BaseSeparator from './base-separator.vue';
export default {
  name: 'BaseCard',
  components: { BaseSeparator },
};
</script>

<style lang="scss" module>
.card {
  background-color: var(--base-background-color);
  border-radius: 4px;
  box-shadow: var(--base-shadow-sm);

  .header {
    display: flex;
    justify-content: space-between;
    padding: var(--base-spacing-sm);
    font-size: 15px;
  }

  .body {
    padding: 18px var(--base-spacing-lg) var(--base-spacing-lg);
  }
}
</style>
