import { statusApi } from '../index';

const resource = '/event/';

export const statusApiEventService = {
  list(params) {
    return statusApi.get(resource, { params });
  },
  get(uuid) {
    return statusApi.get(`${resource}${uuid}/`);
  },
};
