<template>
  <div :class="$style.maintenanceIcon">
    <UcSVG
      origin="most-webapp"
      name="maintenance"
      type="solid"
      height="12px"
      width="12px"
      :class="$style.icon"
    />
  </div>
</template>
<script>
import { UcSVG } from '@unyc/common-components';

export default {
  name: 'MaintenanceIcon',
  components: { UcSVG },
};
</script>

<style lang="scss" module>
.maintenanceIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: var(--color-secondary-100-hex);
  border-radius: 3px;
}
</style>
