import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from '../views/home.vue';
import Category from '../views/category.vue';
import EventPage from '../views/event.vue';
import Subscriptions from '../views/subscriptions.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/category/:uuid',
    name: 'Category',
    component: Category,
  },
  {
    path: '/event/:uuid',
    name: 'Event',
    component: EventPage,
  },
  {
    path: '/subscriptions/',
    name: 'Subscriptions',
    component: Subscriptions,
    meta: {
      isAuthenticated: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to && to.meta && to.meta.isAuthenticated) {
    if (!router.app.$keycloak.authenticated) {
      router.push(Home);
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
