<template>
  <div :class="$style.category">
    <BaseBackLink />

    <EventsAlertsList
      v-if="mainCategoryEventsAlerts.count"
      :events="mainCategoryEventsAlerts.items"
    />

    <CategoryParentCard v-if="mainCategory" :category="mainCategory" />

    <section :class="$style.childrenCategoriesListSection">
      <CategoryChildCard
        v-for="category in mainCategoryChildren.items"
        :key="category.uuid"
        :class="$style.childCategory"
        :category="category"
      />
    </section>

    <CurrentEventsList
      v-if="currentEvents.length"
      :events="currentEvents"
    ></CurrentEventsList>

    <EventHistoryBlock
      :events="mainCategoryClosedEvents.items"
      :total-count="mainCategoryClosedEvents.count"
      @fetch-more-events="fetchEventsNextPage"
    />
  </div>
</template>

<script>
import { statusApiCategoryService } from '../services/models/category.js';
import { statusApiEventService } from '../services/models/event.js';
import { mixinIsPage } from '@/mixins.js';

import BaseBackLink from '../components/base-back-link';

import EventsAlertsList from '../components/events-alerts-list';
import CategoryParentCard from '../components/category-parent-card';
import CategoryChildCard from '../components/category-child-card';
import EventHistoryBlock from '../components/event-history-block';
import CurrentEventsList from '../components/current-events-list';

export default {
  name: 'Category',
  components: {
    BaseBackLink,
    EventsAlertsList,
    CategoryParentCard,
    CategoryChildCard,
    EventHistoryBlock,
    CurrentEventsList,
  },
  mixins: [mixinIsPage],
  data() {
    return {
      mainCategory: null,
      currentEvents: [],
      mainCategoryChildren: {
        count: 0,
        items: [],
        limit: 100,
        offset: 0,
      },
      mainCategoryClosedEvents: {
        count: 0,
        items: [],
        limit: 5,
        offset: 0,
      },
      mainCategoryEventsAlerts: {
        count: 0,
        items: [],
        limit: 3,
        offset: 0,
      },
    };
  },
  computed: {
    mainCategoryUuid() {
      return this.$route.params.uuid;
    },
  },
  methods: {
    fetchParentAndChildrenCategories() {
      statusApiCategoryService.get(this.mainCategoryUuid).then((response) => {
        this.mainCategory = response.data;
      });
      const params = {
        parent: this.mainCategoryUuid,
        limit: this.mainCategoryChildren.limit,
        offset: this.mainCategoryChildren.offset,
      };

      return statusApiCategoryService.list(params).then((response) => {
        this.mainCategoryChildren.items =
          this.mainCategoryChildren.items.concat(response.data.results);
      });
    },
    fetchClosedEvents() {
      const params = {
        category: this.mainCategoryUuid,
        ['is_main']: true,
        status: 'closed',
        limit: this.mainCategoryClosedEvents.limit,
        offset: this.mainCategoryClosedEvents.offset,
      };

      return statusApiEventService.list(params).then((response) => {
        this.mainCategoryClosedEvents.items =
          this.mainCategoryClosedEvents.items.concat(response.data.results);
        this.mainCategoryClosedEvents.count = response.data.count;
      });
    },
    fetchEventsNextPage() {
      this.mainCategoryClosedEvents.offset +=
        this.mainCategoryClosedEvents.limit;
      this.fetchClosedEvents();
    },
    fetchUnfinishedEvents() {
      const params = {
        category: this.mainCategoryUuid,
        ['exclude_status']: 'closed',
        limit: this.mainCategoryEventsAlerts.limit,
        offset: this.mainCategoryEventsAlerts.offset,
      };

      return statusApiEventService.list(params).then((response) => {
        this.mainCategoryEventsAlerts.items =
          this.mainCategoryEventsAlerts.items.concat(response.data.results);
        this.mainCategoryEventsAlerts.count = response.data.count;
      });
    },
    fetchCurrentEvents() {
      const params = {
        category: this.mainCategoryUuid,
        type: ['incident'],
        ['exclude_status']: 'closed',
      };
      return statusApiEventService.list(params).then((response) => {
        this.currentEvents = response.data.results;
      });
    },
    async mountedBeforeCallback() {
      await this.fetchParentAndChildrenCategories();
      await this.fetchUnfinishedEvents();
      await this.fetchClosedEvents();
      await this.fetchCurrentEvents();
    },
  },
};
</script>
<style lang="scss" module>
.category {
  .childrenCategoriesListSection {
    display: flex;
    flex-direction: column;
    gap: var(--base-spacing-md);

    .childCategory {
      display: flex;
      align-items: center;
      padding: var(--base-spacing-md);
      background-color: var(--base-background-color);
      border-radius: 5px;
      box-shadow: var(--base-shadow-sm);
    }
  }
}
</style>
