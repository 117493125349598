<template>
  <div :class="[$style.categoryStatusBlock, $style[direction]]">
    <BaseCircledIcon
      :color="categoryStatusColor"
      :icon-src="category.icon"
      height="36px"
      width="36px"
    />

    <div :class="$style.category">{{ category.name }}</div>
    <div :class="$style.status">{{ category._status }}</div>
    <MaintenanceIcon
      v-if="isMaintenanceScheduled"
      :class="$style.maintenanceIcon"
    ></MaintenanceIcon>
  </div>
</template>

<script>
import BaseCircledIcon from './base-circled-icon.vue';
import MaintenanceIcon from './maintenance-icon.vue';

import { findCategoryStatus } from './../lib/category.js';

const directionValues = ['row', 'column'];

export default {
  name: 'CategoryStatusBlock',
  components: { BaseCircledIcon, MaintenanceIcon },
  props: {
    category: {
      type: Object,
      required: true,
    },
    direction: {
      type: String,
      required: false,
      default: 'column',
      validator: (value) => {
        return directionValues.includes(value);
      },
    },
  },
  computed: {
    categoryStatusColor() {
      const { color } = findCategoryStatus(this.category.status);
      return color;
    },
    isMaintenanceScheduled() {
      return false;
    },
  },
};
</script>

<style lang="scss" module>
@import '../assets/scss/variables';

.categoryStatusBlock {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 150px;
  padding: var(--base-spacing-xl);
  cursor: pointer;
  border-radius: 5px;
  box-shadow: var(--base-shadow-sm);

  .iconWrapper.circle {
    padding-top: 10px;
  }

  &.column {
    .category {
      margin-top: var(--base-spacing-xs);
    }
  }

  &.row {
    flex-direction: row;
    height: auto;
    padding: var(--base-spacing-md);

    .category {
      margin-left: var(--base-spacing-md);
    }

    .status {
      margin-left: auto;
    }
  }
}

.category {
  font-size: 18px;
  font-weight: 500;
  color: black;
  text-align: center;
}

.status {
  margin-top: 3px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.29;
  color: var(--grey-200-hex);
  text-align: center;
}

.maintenanceIcon {
  position: absolute;
  top: 12px;
  right: 10px;
}
</style>
